import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Attach } from '../model/attach.model';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()
export class AttachService {

  constructor(protected http: HttpClient) {}

  download(id: number) {
    return this.http.get(`/ws/media/attach/${id}`, { responseType: 'blob' });
  }

  upload(entity: string, eid: number, file: any): any {

    const formData = new FormData();
    formData.append('file', file);

    const progressSource = new Subject<any>();
    const successSource = new Subject<any>();
    const errorSource = new Subject<any>();
    const completeSource = new Subject<any>();

    this.http.post(`/ws/upload/${entity}/${eid}`, formData, {
      reportProgress: true, observe: 'events'
    }).subscribe(event => {
      const uploadEvent = event as any;
      if (uploadEvent.type === HttpEventType.UploadProgress) {
        progressSource.next({loaded: uploadEvent.loaded, total: uploadEvent.total});
      } else if (uploadEvent.type === HttpEventType.Response) {
        const status = uploadEvent.status;
        const statusText = uploadEvent.statusText;
        const body = uploadEvent.body;
        if (status === 200) {
          successSource.next(plainToClass(Attach, body as Object));
        }
        completeSource.next({status, statusText});
      }
    }, (error) => errorSource.next(error));

    return {
        progress: progressSource.asObservable(),
        success: successSource.asObservable(),
        error: errorSource.asObservable(),
        complete: completeSource.asObservable()
    };
  }

}
