import { Component, Input, OnInit } from '@angular/core';

import { Delivery } from '../../../../@core/model/delivery.model';

@Component({
	selector: 'app-delivery-detail',
	templateUrl: './delivery-detail.component.html',
	styleUrls: ['./delivery-detail.component.scss']
})
export class DeliveryDetailComponent implements OnInit {

	@Input() delivery: Delivery;

	constructor() {}

	ngOnInit() {
		console.log('Delivery in use', this.delivery);
	}

}
