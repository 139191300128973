import { Component, Input, OnInit } from '@angular/core';

import { Parcel } from '../../../../@core/model/parcel.model';

@Component({
	selector: 'app-parcel-detail',
	templateUrl: './parcel-detail.component.html',
	styleUrls: ['./parcel-detail.component.scss']
})
export class ParcelDetailComponent implements OnInit {

	@Input() parcel: Parcel;

	constructor() {}

	ngOnInit() {
		console.log('Parcel in use', this.parcel);
	}

}
