import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VERSION } from '../../../environments/version';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  constructor(public router: Router) {}
  version = VERSION;

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/starter']);
    }
  }
}
