import { Component, OnInit, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CrudService } from '../../../@core/data/crud.service';
import { MessageService } from '../../../@core/data/message.service';
import { Dispatch, DispatchStatus } from '../../../@core/model/dispatch.model';
import { Page } from '../../../@core/model/page.model';
import { Search, RangeFilter, SortDirection } from '../../../@core/model/search.model';
import { TranslateService } from '@ngx-translate/core';
import { Vehicle, VehicleType } from '../../../@core/model/vehicle.model';
import { User, JobCategory } from '../../../@core/model/user.model';
import { ClassType } from 'class-transformer/ClassTransformer';
import { plainToClass } from 'class-transformer';
import { newDate } from '../../../@core/utils/helpers';

@Component({
	selector: 'app-dispatches',
	templateUrl: './dispatches.component.html',
	styleUrls: ['./dispatches.component.scss']
})
export class DispatchesComponent implements OnInit {

	@Input() driver: User;
	@Input() status: string;

	loading: boolean = false;
	page: Page = new Page();
	advancedSearch: Search = new Search('dispatch');
	rows: Dispatch[];

	searchParam = new class {
		vehicle: Vehicle;
		driver: User;
		status: string;
		estdepdate: RangeFilter;
		dispatch: Dispatch;
	};

	statusList: string[] = Object.keys(DispatchStatus).map(key => DispatchStatus[key]);

	driverEntityClass: ClassType<User> = User;
	driverEntitySearch: Search = new Search('orvuser');

	constructor(private crudService: CrudService<Dispatch>,
		private messageService: MessageService,
		private translateService: TranslateService
	) {
		if (!this.driver) {
			this.driverEntitySearch.addSimpleFilter('jobcategory', JobCategory.DRIVER);
			this.driverEntitySearch.setSortDirection(SortDirection.ASCENDING);
			this.driverEntitySearch.setSortField('concat(lname,\' \', fname)');
		}
	}

	ngOnInit() {
		this.search();
	}

	loadData() {
		this.loading = true;
		this.rows = [];

		const datarequest = this.crudService.searchEntities(Dispatch, 'dispatch', this.advancedSearch, this.page);
		const countrequest = this.crudService.countEntities(Dispatch, 'dispatch', this.advancedSearch);

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.page.setTotal(results[1].ct);
			this.rows = results[0];
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error.msg, this.translateService.instant('labels.dispatch'));
			this.loading = false;
		});
	}

	onPageChange(pageInfo: any) {
		this.page.setPageNumber(pageInfo.offset);
		this.loadData();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.loadData();
	}

	search() {

		this.advancedSearch.reset();

		if (this.driver) {
			this.advancedSearch.addSimpleFilter('did', this.driver.id);
			if (this.status) this.advancedSearch.addSimpleFilter('status', this.status);
		} else {
			if (this.searchParam.vehicle) this.advancedSearch.addSimpleFilter('vid', this.searchParam.vehicle.id);
			if (this.searchParam.driver) this.advancedSearch.addSimpleFilter('did', this.searchParam.driver.id);
			if (this.searchParam.estdepdate) {
				const start = this.searchParam.estdepdate.from ? this.searchParam.estdepdate.from : 0;
				const stop = this.searchParam.estdepdate.to ? this.searchParam.estdepdate.to : newDate().getTime();
				this.advancedSearch.addRangeFilter('estdepdate', (start / 1000), (stop / 1000));
			}
			if (this.searchParam.dispatch) this.advancedSearch.addSimpleFilter('lmsid', this.searchParam.dispatch.lmsid);
			if (this.searchParam.status) this.advancedSearch.addSimpleFilter('status', this.searchParam.status);
		}

		this.page.setPageNumber(0);
		this.loadData();
	}

	rowClass(row) {
		return row.vid === 0 && row.did === 0 ? 'alert-row' : '';
	}

	getLmsIdFormatted = (value: any) => {
		value = plainToClass(Dispatch, value);
		if (!value.vtypename) return;
		return `${value.lmsid} - ${this.translateService.instant(value.vtypename)}`;
	}

}
