import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { Adapter, AdapterStatus } from '../model/adapter.model';
import { Conf } from '../model/conf.model';

@Injectable()
export class AdapterService {

    httpOptions: any;

    constructor(protected http: HttpClient) {

       
        
    }

    getAdapter(machinename: string): Observable<Adapter> {
        return this.http.get(`/ws/adapter/${machinename}` )
            .map(res => plainToClass(Adapter, res as Object));
    }

    getAdapters(): Observable<any> {
        return this.http.get(`/ws/adapter` )
            .map(res => plainToClass(Adapter, res as Object));
    }

    saveAdapter(adapter: Adapter): Observable<any> {
        return this.http.post(`/ws/adapter`, adapter )
            .map(res => plainToClass(Adapter, res as Object));
    }

    getStatus(machinename: string): Observable<AdapterStatus> {
        return this.http.get(`/ws/adapter/${machinename}/status` )
            .map(res => plainToClass(AdapterStatus, res as Object));
    }

    saveConf(machinename: string, confs: Conf[]): Observable<any> {
        return this.http.post(`/ws/adapter/${machinename}/conf`, confs );
    }

    updateAction(machinename: string, action: string): Observable<any> {
        return this.http.post(`/ws/adapter/${machinename}/${action}`, {} );
    }

}
