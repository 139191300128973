import { Type, Exclude } from 'class-transformer';
import { LatLng, LatLngLiteral } from '@agm/core';

declare const google;

export class Geofence {
    id: number;
    centerlat: number;
    centerlng: number;
    radius: number;
    type: string;
    name: string;

    @Type(geopoint)
    points: Array<Geopoint>;

    constructor(type: string, centerlat?: number, centerlng?: number, radius?: number, points?: LatLng[]) {
            this.type = type;
            this.centerlat = centerlat;
            this.centerlng = centerlng;
            this.radius = radius;

        if (points && points.length > 2) {
            this.points = [];
            for (let index = 0; index < points.length; index++) {
               this.points.push(new Geopoint( points[index].lat(), points[index].lng(), index));
            }
        }
	}

	get mapColor() {
		return GeofenceColors[this.type.toUpperCase()] || GeofenceColors.DEFAULT;
	}


    update(points: any) {

        delete this.points;
        this.points = [];
        const bounds = new google.maps.LatLngBounds();

        for (let index = 0; index < points.length; index++) {
            this.points.push(new Geopoint(points[index].lat(), points[index].lng(), index));
            bounds.extend(points[index]);
         }

         console.log(bounds);

         this.centerlat = bounds.getCenter().lat();
         this.centerlng = bounds.getCenter().lng();
        this.radius = google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), bounds.getSouthWest()) / 2;
    }

}

export class Geopoint implements LatLngLiteral {
    id: number;
    lat: number;
    lng: number;
    sort: number;

    constructor(lat, lng, sort) {
        this.sort = sort;
        this.lat = lat;
        this.lng = lng;
    }
}

export class GeofenceAttr {

	constructor(gid: string) {
		this.gid = parseInt(gid, 10);
	}

	id?: number;
	gid: number;
	attrkey: string;
	attrvalue?: string;
	@Exclude({ toPlainOnly: true}) edit?: boolean;
}

export enum GeofenceColors {
	TOLL = '#55ce63',
	FUELSTATION = '#ffbc34',
	SAFEAREA = '#f62d51',
	OTHER = '#7460ee',
	DEFAULT = '#000000'
}

export function geopoint() {
	return Geopoint;
}
