import { Component, OnInit, OnDestroy } from '@angular/core';
import { AnonymousSubscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-hive-dashboard',
  templateUrl: './hive-dashboard.component.html',
  styleUrls: []
})
export class HiveDashboardComponent  {



  constructor() { }


}
