import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedDataService } from './shared-data.service';
import { UserService } from './user.service';
import { CrudService } from './crud.service';
import { VehicleService } from './vehicle.service';
import { MessageService } from './message.service';
import { AddressService } from './address.service';
import { AdapterService } from './adapter.service';
import { RoleService } from './role.service';
import { ListService } from './list.service';
import { AclService } from './acl.service';
import { LocationService } from './location.service';
import { GoogleService } from './google.service';
import { ModalService } from './modal.service';
import { PrecheckService } from './precheck.service';
import { CustomerService } from './customer.service';
import { DeliveryService } from './delivery.service';
import { DispatchService } from './dispatch.service';
import { ParcelService } from './parcel.service';
import { AttachService } from './attach.service';

const SERVICES = [
  UserService,
  SharedDataService,
  CrudService,
  VehicleService,
  MessageService,
  AddressService,
  AdapterService,
  RoleService,
  ListService,
  LocationService,
  CustomerService,
  DeliveryService,
  DispatchService,
  ParcelService,
  GoogleService,
  ModalService,
  AclService,
  PrecheckService,
  AttachService
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
