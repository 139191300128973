import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CrudService } from '../../../@core/data/crud.service';
import { MessageService } from '../../../@core/data/message.service';
import { Dispatch } from '../../../@core/model/dispatch.model';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-dispatch',
	templateUrl: './dispatch.component.html',
	styleUrls: []
})
export class DispatchComponent implements OnInit {

	@Input() dispatch: Dispatch;

	loading: boolean = false;

	constructor(
		private crudService: CrudService<Dispatch>,
		private messageService: MessageService,
		private translateService: TranslateService,
		private route: ActivatedRoute
	) {
	}

	ngOnInit() {
		if (!this.dispatch) {
			this.route.params.subscribe(params => {
				const id = +params['id'];
				this.loadDispatch(id);
			});
		}
	}

	loadDispatch(id: number) {
		this.loading = true;
		this.crudService.getEntity(Dispatch, 'dispatch', id).subscribe(dispatch => {
			this.dispatch = dispatch;
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error, this.translateService.instant('labels.dispatch'));
			this.loading = false;
		});
	}



}
