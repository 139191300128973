import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiveDashboardRoutingModule } from './hive-dashboard-routing.module';
import { HiveDashboardComponent } from './hive-dashboard.component';


@NgModule({
  imports: [
    CommonModule,
    HiveDashboardRoutingModule
  ],
  declarations: [
      HiveDashboardComponent
  ]
})
export class HiveDashboardModule { }
