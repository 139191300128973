
export class Page {

    size: number = 0; // The number of elements in the page
    // The total number of elements
    totalElements: number = 0;
    // The total number of pages
    totalPages: number = 0;
    // The current page number
    pageNumber: number = 0;

    start: number = 0;
    stop: number = this.start + this.size;
    constructor(size: number = 20) {
        this.size = size;
        this.stop = this.start + this.size;
    }

    setPageNumber(n: number, elementsPerRow: number  = 1 ) {
        this.pageNumber = n;
        this.start = this.pageNumber * this.size * elementsPerRow;
        this.stop = this.start + this.size * elementsPerRow;
    }

    setTotal(t: number) {
        this.totalElements = t;
        this.totalPages = Math.ceil(t / this.size );
    }

}
