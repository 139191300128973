import { Address } from './address.model';
import { MapMarker } from './map.model';
import { Exclude } from 'class-transformer';

export class Customer implements MapMarker {

    id: number;
    name: string;
    contact: string;
    email: string;
    mobileno: string;
    landline: string;
    ctype: string;
    parent: number;
    lastupdate: number;
    lmsid: number;

    address?: Address;

    @Exclude()
    clickable: boolean = false;
    @Exclude()
    infoWindow: boolean = false;
    @Exclude()
    get lat() {
        return this.address ? this.address.lat : undefined;
    }
    @Exclude()
    get lng() {
        return this.address ? this.address.lng : undefined;
    }
}

export enum CustomerType {
    billing = 'billing',
    delivery = 'delivery'
}
