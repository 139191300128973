import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '../../../../@core/model/customer.model';
import { CrudService } from '../../../../@core/data/crud.service';
import { MessageService } from '../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  loading: boolean = false;
  customer: Customer;

  constructor(private crudService: CrudService<Customer>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params['id'];
      this.loadCustomer(id);
    });
  }

  loadCustomer(id) {
    this.loading = true;
    this.crudService.getEntity(Customer, 'customer', id).subscribe(customer => {
      this.customer = customer;
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.customer'));
      this.loading = false;
    });
  }

}
