
export class Role {
    id: number;
    urole: string;
    descr: string;
}

export class RoleFeature {
    id: number;
    rid: number;
    fid: number;
}
