import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachService } from '../../../data/attach.service';
import { MessageService } from '../../../data/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-attachment-upload',
  templateUrl: './attachment-upload.component.html',
  styleUrls: ['./attachment-upload.component.scss']
})
export class AttachmentUploadComponent implements OnInit {

  @Input() entity: string;
  @Input() eid: number;

  filename: any;
  file: any;
  loading: boolean = false;
  progress: number;

  constructor(private attachService: AttachService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

  onChange(event: any) {
    const files = event.target.files || event.dataTransfer.files;
    this.file = files && files.length ? files[0] : null;
  }

  save() {
    this.loading = true;
    const events = this.attachService.upload(this.entity, this.eid, this.file);
    events.success.subscribe(() => {
      this.messageService.sendSuccess(this.translateService.instant('labels.attach-save-success'),
        this.translateService.instant('labels.attach'));
      this.activeModal.close();
      this.loading = false;
    });
    events.error.subscribe(({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.attach'));
      this.loading = false;
    });
    events.progress.subscribe(response => {
        this.progress = Math.round(response.loaded * 100 / response.total);
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
