import { Component, OnInit, Input, forwardRef, HostBinding } from '@angular/core';
import { ListService } from '../../data/list.service';
import { ListItem } from '../../model/list.model';
import { MessageService } from '../../data/message.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-list-items-selector',
	templateUrl: './list-items-selector.component.html',
	styleUrls: ['./list-items-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ListItemsSelectorComponent),
			multi: true
		}
	]
})
export class ListItemsSelectorComponent implements OnInit, ControlValueAccessor {

	@Input() list: string;
	@Input() emptyOption: boolean = false;
	@Input() name: string;
	@Input() required: boolean = false;
	@Input() disabled: boolean = false;
	@Input() inputClass: string;

	selectedItem: string;
	arrayList: ListItem[] = [];
	loading: boolean = false;

	onChange = (value: any) => {};

	constructor(
		private listService: ListService,
		private messageService: MessageService
	) { }

	ngOnInit() {
		this.loadData();
	}

	writeValue(value: string) {
		if (typeof value !== 'undefined') this.selectedItem = value;
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
	}

	loadData() {
		this.loading = false;
		this.listService.getListItemsBySlug(this.list).subscribe(
			results => {
				this.arrayList = results;
				this.loading = false;
			},
			({error}) => {
				this.messageService.sendError(error, error.code);
				this.loading = false;
			}
		);
	}

	onSelectItem(event) {
		if (typeof event === 'undefined') return;
		this.onChange(event);
	}

}
