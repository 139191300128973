import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Conf } from '../../model/conf.model';
import { CrudService } from '../../data/crud.service';
import { MessageService } from '../../data/message.service';

@Component({
  selector: 'app-confs',
  templateUrl: './confs.component.html',
  styleUrls: ['./confs.component.scss']
})
export class ConfsComponent implements OnInit {

  loading: boolean = false;
  rows: any[];
  settings: Conf[];

  confkey: string;
  confvalue: string;

  @Input()
  set prefix(prefix: any) {
    this.loadSettings(prefix);
  }

  constructor(private crudService: CrudService<Conf>,
    private messageService: MessageService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  loadSettings(prefix: any) {
    this.loading = true;
    this.crudService.getAll(Conf, 'conf').subscribe(confs => {
      if (!prefix) return true;
      this.settings = confs.filter(conf => {
        const value = prefix.value;
        const condition = prefix.condition;
        const index = conf.confkey.indexOf(value);
        return condition === 'eq' ? index === 0 :
          condition === 'ne' ? index !== 0 : true;
      } );
      this.filterSettings();
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.setting'));
      this.loading = false;
    });
  }

  onFormChange() {
    this.filterSettings();
  }

  filterSettings() {
    this.rows = this.settings.filter(item => {
      const existsKey = !this.confkey || item.confkey.indexOf(this.confkey) >= 0;
      const existsValue = !this.confvalue || item.confval.indexOf(this.confvalue) >= 0;
      return existsKey && existsValue;
    }).map(item => ({ edit: false, conf: cloneDeep(item) }));
  }

  undo(row: any) {
    const index = this.settings.map(item => item.id).indexOf(row.conf.id);
    row.conf = cloneDeep(this.settings[index]);
    row.edit = false;
  }

  confirm(row: any) {
    const newConf = row.conf;
    this.crudService.saveEntity(Conf, 'conf', newConf).subscribe(conf => {
      const index = this.settings.map(item => item.id).indexOf(row.conf.id);
      this.settings[index] = conf;
      row.conf = cloneDeep(this.settings[index]);
      row.edit = false;
      const messages = this.translateService.instant(['labels.setting-save-success', 'labels.setting']);
      this.messageService.sendSuccess(messages['labels.setting-save-success'], messages['labels.setting']);
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.setting'));
    });
  }

}
