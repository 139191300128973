import { Injectable, Type } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedDataService } from './shared-data.service';
import { CookieService } from 'ngx-cookie-service';
import { plainToClass, classToPlain } from 'class-transformer';
import { Observable } from 'rxjs/Observable';
import { ClassType } from 'class-transformer/ClassTransformer';
import { Page } from '../model/page.model';

@Injectable()
export class CrudService<T> {

  httpOptions: any;
  token: string;

  constructor(protected http: HttpClient) {}

  getAll(entityClass: ClassType<T>, entityName: string): Observable<any> {
    return this.http.get( `/ws/${entityName}` )
    .map(res => plainToClass(entityClass, res as Object));
  }

  getFiltered(entityClass: ClassType<T>, entityName: string, filter: string, term: string): Observable<any> {
    return this.http.get( `/ws/${entityName}/filter/${filter}/${term}/range/0/1000` )
    .map(res => plainToClass(entityClass, res as Object));
  }

  getEntities(entityClass: ClassType<T>, entityName: string, page: Page, sort: string = 'asc'): Observable<any> {
    return this.http.get( `/ws/${entityName}/range/${page.start}/${page.stop}/${sort}` )
      .map(res => plainToClass(entityClass, res as Object));
  }

  getCount(entityClass: ClassType<T>, entityName: string): Observable<any> {
    return this.http.get(`/ws/${entityName}/count`);
  }

  getEntity(entityClass: ClassType<T>, entityName: string, id: number): Observable<any> {
    return this.http.get( `/ws/${entityName}/${id}`)
      .map(res => plainToClass(entityClass, res as Object));
  }

  saveEntity(entityClass: ClassType<T>, entityName: string, entity: any): Observable<any> {
    return this.http.put( `/ws/${entityName}/${entity.id}`, classToPlain(entity) )
      .map(res => plainToClass(entityClass, res as Object));
  }

  createEntity(entityClass: ClassType<T>, entityName: string, entity: any): Observable<any> {
    return this.http.post( `/ws/${entityName}`, classToPlain(entity) )
      .map(res => plainToClass(entityClass, res as Object));
  }

  deleteEntity(entityClass: ClassType<T>, entityName: string, entity: any): Observable<any> {
    return this.http.delete( `/ws/${entityName}/${entity.id}`);
  }

  searchEntities(entityClass: ClassType<T>, entityName: string, search: any, page: Page): Observable<any> {
    return this.http.post( `/ws/${entityName}/advancedsearch/${page.start}/${page.stop}`, classToPlain(search) )
      .map(res => plainToClass(entityClass, res as Object));
  }

  countEntities(entityClass: ClassType<T>, entityName: string, search: any): Observable<any> {
    return this.http.post(`/ws/${entityName}/advancedcount`, classToPlain(search) )
      .map(res => plainToClass(entityClass, res as Object));
  }

	altSearchEntities(entityClass: ClassType<T>, entityName: string, search: any, page: Page): Observable<any> {
		return this.http.post(`/ws/${entityName}/advancedsearch/${page.start}/${page.stop}`, classToPlain(search) )
			.map(res => plainToClass(entityClass, res as Object));
	}

	altCountEntities(entityClass: ClassType<T>, entityName: string, search: any): Observable<any> {
		return this.http.post(`/ws/${entityName}/advancedcount`, classToPlain(search) )
			.map(res => plainToClass(entityClass, res as Object));
	}




}
