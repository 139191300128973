import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Dispatch } from '../../../../@core/model/dispatch.model';
import { LocationService } from '../../../../@core/data/location.service';
import { MessageService } from '../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { MapComponent } from '../../../../@core/core-components/map/map.component';
import { DataRefresh } from '../../../../@core/core-components/data-refresh/data-refresh';
import { MixinRoot } from '../../../../@core/utils/mixins';
import { MapMarker, MapPosition } from '../../../../@core/model/map.model';
import { Vehicle } from '../../../../@core/model/vehicle.model';
import { User, JobCategory } from '../../../../@core/model/user.model';
import { Search, SortDirection } from '../../../../@core/model/search.model';
import { ClassType } from 'class-transformer/ClassTransformer';
import { CrudService } from '../../../../@core/data/crud.service';
import { cloneDeep } from 'lodash';
import { AclService } from '../../../../@core/data/acl.service';

const DELTA = 100000;

@Component({
	selector: 'app-dispatch-detail',
	templateUrl: './dispatch-detail.component.html',
	styleUrls: ['./dispatch-detail.component.scss']
})
export class DispatchDetailComponent extends DataRefresh(MixinRoot) implements OnInit, OnDestroy {

	@Input() dispatch: Dispatch;

	@ViewChild('map') map: MapComponent;

	vehicle: Vehicle;
	driver: User;

	driverEntityClass: ClassType<User> = User;
	driverEntitySearch: Search = new Search('orvuser');

	loading: boolean = false;
	locations: any[] = [];
	polyline: MapPosition[];
	markers: MapMarker[];
	firsttime: boolean = true;
	start: number = 0;
	stop: number = DELTA;

	constructor(private locationService: LocationService,
		private crudService: CrudService<Dispatch>,
		public aclService: AclService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private cdr: ChangeDetectorRef) {
		super();
	}

	ngOnInit() {
		if (this.dispatch.isStatusOnRide) {
			// driver search
			this.driverEntitySearch.addSimpleFilter('jobcategory', JobCategory.DRIVER);
			this.driverEntitySearch.setSortDirection(SortDirection.ASCENDING);
			this.driverEntitySearch.setSortField('concat(lname,\' \', fname)');
		}
		this.refreshData();
		this.init(30 * 1000);
	}

	ngOnDestroy() {
		this.destroy();
	}

	refreshData() {
		this.loadLocations();
	}

	loadLocations() {
		if (this.firsttime) {
			this.loading = true;
		}
		this.locationService.getLocationsByDispatch(this.dispatch.id, this.start, this.stop).subscribe(locations => {
			this.locations = [...this.locations, ...locations];
			this.polyline = this.locations;
			this.start = this.locations.length;
			this.stop = this.start + DELTA;
			if (locations.length) {
				const startMarker: MapMarker = {
					id: this.locations[0].id,
					lat: this.locations[0].lat,
					lng: this.locations[0].lng,
					icon: 'assets/images/map/pointer-start.svg'
				};
				this.markers = [startMarker];
				if (!this.dispatch.isStatusOnRide) {
					const lastIndex = this.locations.length - 1;
					const endMarker: MapMarker = {
						id: this.locations[lastIndex].id,
						lat: this.locations[lastIndex].lat,
						lng: this.locations[lastIndex].lng,
						icon: 'assets/images/map/pointer-end.svg'
					};
					this.markers = [startMarker, endMarker];
				}
			}
			this.loading = false;
			this.firsttime = false;
			this.cdr.detectChanges();
			if (this.dispatch.isStatusOnRide) super.refreshData();
		}, ({ error }) => {
			this.messageService.sendError(error.msg, this.translateService.instant('labels.dispatch'));
			this.loading = false;
		});
	}

	submit() {
		const newDispatch = cloneDeep(this.dispatch);
		newDispatch.did = newDispatch.driver.id;
		newDispatch.vid = newDispatch.vehicle.id;
		this.crudService.saveEntity(Dispatch, 'dispatch', newDispatch).subscribe(() => {
			this.messageService.sendSuccess(this.translateService.instant('labels.dispatch-save-success'),
				this.translateService.instant('labels.dispatch'));
		}, ({ error }) => {
			this.messageService.sendError(error.msg, this.translateService.instant('labels.dispatch'));
		});
	}
}
