import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Customer } from '../model/customer.model';
import 'rxjs/add/operator/map';

@Injectable()
export class CustomerService {

  httpOptions: any;

  constructor(protected http: HttpClient) {}

  getNearbyCustomers(lat: number, lng: number, meters: number): Observable<any> {
    return this.http.get(`/ws/customer/nearby/${lat}/${lng}/${meters}` )
        .map(res => plainToClass(Customer, res as Object));
  }

}
