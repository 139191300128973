import { Component, OnInit, Input } from '@angular/core';
import { Dispatch } from '../../../../@core/model/dispatch.model';
import { Customer, CustomerType } from '../../../../@core/model/customer.model';
import { Page } from '../../../../@core/model/page.model';
import { Search, RangeFilter, SortDirection } from '../../../../@core/model/search.model';
import { Delivery } from '../../../../@core/model/delivery.model';
import { CrudService } from '../../../../@core/data/crud.service';
import { MessageService } from '../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { newDate } from '../../../../@core/utils/helpers';

@Component({
  selector: 'app-deliveries-list',
  templateUrl: './deliveries-list.component.html',
  styleUrls: ['./deliveries-list.component.scss']
})
export class DeliveriesListComponent implements OnInit {

  	@Input() dispatch: Dispatch;
	@Input() customer: Customer;

	loading: boolean = false;
	page: Page = new Page();
	advancedSearch: Search = new Search('delivery');
  	rows: Delivery[];

	billingCustomer: Customer;
	deliveryCustomer: Customer;
	did: number;
	deliverydate: RangeFilter;

	billingCustomerEntitySearch: Search = new Search('customer');
	deliveryCustomerEntitySearch: Search = new Search('customer');

	constructor(
		private crudService: CrudService<Delivery>,
		private messageService: MessageService,
		private translateService: TranslateService
	) {
	}

	ngOnInit() {
		if (!this.dispatch && !this.customer) {
			// init billing customer default search (autocomplete)
			this.billingCustomerEntitySearch.addSimpleFilter('ctype', CustomerType.billing);
			this.billingCustomerEntitySearch.setSortDirection(SortDirection.ASCENDING);
			this.billingCustomerEntitySearch.setSortField('name');
			// init delivery customer default search (autocomplete)
			this.deliveryCustomerEntitySearch.addSimpleFilter('ctype', CustomerType.delivery);
			this.deliveryCustomerEntitySearch.setSortDirection(SortDirection.ASCENDING);
			this.deliveryCustomerEntitySearch.setSortField('name');
		}
		this.search();
	}

	loadData() {
		this.loading = true;
		this.rows = [];
		let datarequest, countrequest;

		if (!this.customer) {
			datarequest = this.crudService.searchEntities(Delivery, 'delivery', this.advancedSearch, this.page);
			countrequest = this.crudService.countEntities(Delivery, 'delivery', this.advancedSearch);
		} else {
			// when api ready update the service on those 2 methods below
			datarequest = this.crudService.altSearchEntities(Delivery, 'delivery', this.advancedSearch, this.page);
			countrequest = this.crudService.altCountEntities(Delivery, 'delivery', this.advancedSearch);
		}

		forkJoin([datarequest, countrequest]).subscribe(results => {
			this.page.setTotal(results[1].ct);
			this.rows = results[0];
			this.loading = false;
		}, ({ error }) => {
			this.messageService.sendError(error.msg, this.translateService.instant('labels.delivery'));
			this.loading = false;
		});
	}

  	onPageChange(pageInfo: any) {
		this.page.setPageNumber(pageInfo.offset);
		this.loadData();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.loadData();
	}

	search() {

		this.advancedSearch.reset();

		if (this.dispatch) {
			this.advancedSearch.addSimpleFilter('did', this.dispatch.id);
		} else if (this.customer) {
			this.advancedSearch.addSimpleFilter('customerid', this.customer.id);
		} else {
			if (this.billingCustomer) this.advancedSearch.addSimpleFilter('billingcustid', this.billingCustomer.id);
			if (this.deliveryCustomer) this.advancedSearch.addSimpleFilter('customerid', this.deliveryCustomer.id);
			if (this.did) this.advancedSearch.addSimpleFilter('did', this.did);
			if (this.deliverydate) {
				const start = this.deliverydate.from ? this.deliverydate.from : 0;
				const stop = this.deliverydate.to ? this.deliverydate.to : newDate().getTime();
				this.advancedSearch.addRangeFilter('deliverydate', (start / 1000), (stop / 1000));
			}
		}

		this.page.setPageNumber(0);
		this.loadData();
	}

}
