import { User } from './user.model';
import { Vehicle, VehicleType } from './vehicle.model';
import { Type } from 'class-transformer';
import { Delivery } from './delivery.model';

export class Dispatch {

	id: number;
	lmsid: number;
	vid: number;
	did: number;
	status: string;
	startdate?: number;
	stopdate?: number;
	vehicletype: number;
	uid?: number; // user id, usually NULL
	estdepdate?: number;
	trailer: number;
	trailer2: number;
	fromlocid: number;
	tolocid?: number;
	site: string;
	lastupdate: number;
	@Type(() => User)
	driver?: User;
	@Type(() => Vehicle)
	vehicle?: Vehicle;
	etastatus: string;
    deliveries: Delivery[];

	// TODO: move or reformat to use the VehicleType enum
	get vtypename() {
		let name = '';
		switch (this.vehicletype) {
			case 2:
				name = 'labels.linehaul';
				break;
			case 6:
				name = 'labels.distribution';
				break;
			case 8:
				name = 'labels.holding';
				break;
			default:
				break;
		}
		return name;
	}

	get isStatusOnRide() {
		return this.status === DispatchStatus.ONRIDE;
	}

	get isStatusIdle() {
		return this.status === DispatchStatus.IDLE;
	}

	get isVehicleTypeDistribution() {
		return this.vehicletype === VehicleType.DISTRIBUTION;
	}
}

export class DispatchStart {
	id: number;
	did: number;
	dverifmethod: string;
	dverifvalue: string;
	dverifdate: number;
	vverifmethod: string;
	vverfifvalue?: string;
	vverifdate: number;
	sverifmethod: string;
	sverifvalue: string;
	sverifdate: number;
	sveriflat: number;
	sveriflng: number;
	precheckstartdate: number;
	precheckstopdate?: number;
	hazmatstartdate?: number;
	hazmatstopdate?: number;
	dispatchstartdate: number;
}


export class DispatchPolyline {
	id: number;
	did: number;
	expolyline: string;
	actualpolyline: string;
}

export enum DispatchStatus {
	IDLE = 'idle',
	ONRIDE = 'onride',
	COMPLETED = 'completed'
}
