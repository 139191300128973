import { Component, OnInit, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { Geocoding } from '../../../../@core/model/geocoding.model';
import { RangeFilter } from '../../../../@core/model/search.model';
import { MessageService } from '../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryService } from '../../../../@core/data/delivery.service';
import { Delivery } from '../../../../@core/model/delivery.model';
import { MapComponent } from '../../../../@core/core-components/map/map.component';
import { newDate } from '../../../../@core/utils/helpers';
import { MapMarker } from '../../../../@core/model/map.model';

@Component({
  selector: 'app-deliveries-map',
  templateUrl: './deliveries-map.component.html',
  styleUrls: ['./deliveries-map.component.scss']
})
export class DeliveriesMapComponent implements OnInit {

  @Input() delivery: Delivery;

	@ViewChild('map') map: MapComponent;

	markers: MapMarker[];

  address: any;
  addressGeocoded: Geocoding;
	metres: number = 500;
	range: RangeFilter;

	constructor(private deliveryService: DeliveryService,
		private messageService: MessageService,
		private translateService: TranslateService,
		private cdr: ChangeDetectorRef) {
    this.initRange();
	}

	ngOnInit() {
  }

  search() {
    // move map to address
    this.map.moveMap(this.addressGeocoded.geometry.location.lat, this.addressGeocoded.geometry.location.lng, 15);
    // load nearby deliveries
    const location = this.addressGeocoded.geometry.location;
    const start = this.range && this.range.from ? this.range.from : 0;
    const stop = this.range && this.range.to ? this.range.to : newDate().getTime();
    this.deliveryService.getNearbyDeliveries(location.lat, location.lng, this.metres, start, stop).subscribe(deliveries => {
        this.markers = [];
        deliveries.forEach(delivery => {
          delivery.clickable = true;
          delivery.infoWindow = true;
          this.markers.push(delivery);
        });
        this.cdr.detectChanges();
      }, ({error}) => {
        this.messageService.sendError(error, this.translateService.instant('labels.delivery'));
      });
  }

  initRange() {
    this.range = new RangeFilter();
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    d.setDate(1);
    this.range.from = d.getTime();
    d.setMonth(d.getMonth() + 1);
    d.setDate(d.getDate() - 1);
		this.range.to = d.getTime();
  }

	onMetresChange() {
    if (this.metres) {
      if (this.metres <= 0) this.metres = 1;
      else if (this.metres > 5000) this.metres = 5000;
    }
  }

	onAddressGeocoded(event: Geocoding[]) {
		if (event) {
			this.addressGeocoded = event[0];
		} else {
			this.messageService.sendWarn(
				this.translateService.instant('labels.no-results-founds'),
				this.translateService.instant('labels.delivery'));
		}
  }

}
