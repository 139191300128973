import { DatePipe } from '@angular/common';
import { Type } from 'class-transformer';
import { MapMarker } from './map.model';

export class VehicleMapItem implements MapMarker {

    datePipe = new DatePipe('en');

    fleetcode: string;
    vid: number;
    lat: number;
    lng: number;
    speed: number;
    heading: number;
    lastdate: number;

    get lastupdate() {
        return this.lastdate;
    }

    get icon() {
        if (this.speed === 0) {
            return 'assets/images/map/pointer-no-sign.svg';
        } else {
            // 0: n, 1: ne, 2: e, 3: se, 4: s, 5: sw, 6: w, 7: nw
            const direction = Math.floor(this.heading / 45);
            switch (direction) {
                case 0:
                    return 'assets/images/map/pointer-n.svg';
                case 1:
                    return 'assets/images/map/pointer-ne.svg';
                case 2:
                    return 'assets/images/map/pointer-e.svg';
                case 3:
                    return 'assets/images/map/pointer-se.svg';
                case 4:
                    return 'assets/images/map/pointer-s.svg';
                case 5:
                    return 'assets/images/map/pointer-sw.svg';
                case 6:
                    return 'assets/images/map/pointer-w.svg';
                case 7:
                    return 'assets/images/map/pointer-nw.svg';
                default:
                    break;
            }
        }
    }

    get label() {
        return this.fleetcode;
    }

    get clickable() {
        return true;
    }

    get infoWindow() {
        return true;
    }

    get id() {
        return this.vid;
    }

}

export class VehicleClusterMap {

    @Type(() => (VehicleClusterMapItem))
    clusters: VehicleClusterMapItem[];
    maxcount: number;
    mincount: number;
}

export class VehicleClusterMapItem implements MapMarker {

    ct: number;
    fleetcode: string;
    lat: number;
    lng: number;
    vid: number;

    get id() {
        return this.ct + '_' + this.lat + ':' + this.lng;
    }

    get icon() {
        if (this.ct < 10)
            return 'assets/images/map/cluster-blue.svg';
        else if (this.ct < 100)
            return 'assets/images/map/cluster-yellow.svg';
        else
            return 'assets/images/map/cluster-red.svg';
    }

    get label() {
        return this.ct.toString();
    }

    get clickable() {
        return true;
    }

}
