import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-heartbit-point',
  templateUrl: './heartbit-point.component.html',
  styleUrls: ['./heartbit-point.component.scss']
})
export class HeartbitPointComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
