import { Type, Expose } from 'class-transformer';
import { DatePipe } from '@angular/common';

export class Vehicle {

    datePipe = new DatePipe('en');

    id: number;
    regno: string;
    fleetcode: string;
    maker: string;
    @Expose({ name: 'modek'})
    model: string;
    series: string;
    year: number;
    engineno: string;
    chassisno: string;
    color: string;
    bodyl: number;
    bodyw: number;
    owned: number;
    status: number;
    insdate: number;
    lastupdate: number;
    branch: string;
    trackingprovider: string;
    trackingserial: string;
    division: string;
    tyresize: string;
    tyresizealt: string;
    depotcode: string;
    gvm: number;
    tare: number;
    payload: number;
    tyreno: number;
    tyresteering: number;
    adv: number;
    fleximount: number;
    vclass: string;
    fuel: string;
    rangekm: number;
    fuelcapacity: number;
    targetfuel: number;
    targetconsumption: number;
    avgconsumption: number;
    fuelcard: string;
    tollclass: string;
    bdoorh: number;
    bdoorw: number;
    lmsid: number;
    sapcode: string;
    active: string;

    get displayModel() {
        return `${this.maker} ${this.model} ${this.year} ${this.color}`;
    }
}

export enum VehicleType {
	LINEHAUL = 2,
	DISTRIBUTION = 6,
	HOLDING = 8
}


