import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/filter';

@Injectable()
export class AppRouterService {

    private navigations: any[];

    constructor(private router: Router) {
        this.router.events
            .filter(e => e instanceof NavigationEnd)
            .pairwise().subscribe(event => {
                this.navigations = event;
            }
        );
    }

    getPreviousUrl() {
        return this.navigations[1].url;
    }

    getCurrenUrl() {
        return this.navigations[0].url;
    }
}
