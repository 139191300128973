import { AfterViewInit, Component, ElementRef, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements AfterViewInit {
  // this is for the open close
  isActive = true;
  showMenu = '';
  showSubMenu = '';

  // filtering the menu
  searchMenu: string = '';
  menuToFilter: QueryList<ElementRef>;
  @ViewChildren('menu') menu: QueryList<ElementRef>;

  constructor(private renderer: Renderer2) {
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  eventCalled() {
    this.isActive = !this.isActive;
  }
  // End open close
  ngAfterViewInit() {
    $(function() {
      $('.sidebartoggler').on('click', function() {
        if ($('body').hasClass('mini-sidebar')) {
          $('body').trigger('resize');
          $('.scroll-sidebar, .slimScrollDiv')
            .css('overflow', 'hidden')
            .parent()
            .css('overflow', 'visible');
          $('body').removeClass('mini-sidebar');
		  $('.navbar-brand span').show();
		  $('.search-input').show();
          // $(".sidebartoggler i").addClass("ti-menu");
        } else {
          $('body').trigger('resize');
          $('.scroll-sidebar, .slimScrollDiv')
            .css('overflow-x', 'visible')
            .parent()
            .css('overflow', 'visible');
          $('body').addClass('mini-sidebar');
		  $('.navbar-brand span').hide();
			$('.search-input').hide();
          // $(".sidebartoggler i").removeClass("ti-menu");
        }
      });
	});
  }

  filterMenu(event: string) {
	  this.menuToFilter = cloneDeep(this.menu);
	  this.menuToFilter.forEach(item => {
		if (!item.nativeElement.innerText.toLowerCase().includes(event.toLowerCase())) {
			this.renderer.setStyle(item.nativeElement, 'display', 'none');
		} else {
			this.renderer.setStyle(item.nativeElement, 'display', 'inherit');
		}
	  });
  }

}
