
export class User {

    id: number;
    email: string;
    token: string;
    username: string;
    fname: string;
    lname: string;
    password: string;
    insdate: number;
    empcode: string;
    aka: string;
    gender: string;
    cellno: string;
    rsaid: string;
    passportno: string;
    idpassportno: string;
    nationality: string;
    birthcountry: string;
    engageddate: number;
    joineddate: number;
    termindate: number;
    status: string;
    depot: string;
    jobcategory: string;
    jobtitle: string;
    payroll: string;
	annualsalary: number;

	get fullname() {
		return (this.fname && this.lname) ? `${this.fname} ${this.lname}` : '';
	}

	get displayName() {
		return this.aka ? this.aka : this.fullname;
    }

    get isDriver() {
        return this.jobcategory === JobCategory.DRIVER;
    }
}

export class UserRole {
    id: number;
    rid: number;
    uid: number;
}

export enum JobCategory {
    DRIVER = 'DRIVER'
}

export enum Gender {
    MALE = 'M',
    FEMALE = 'F'
}
