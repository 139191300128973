export class Parcel {
	id: number;
	lmsid: number;
	weight: number;
	pcs: number;
	lenght: number;
	heigth: number;
	width: number;
	barcode: string;
	volweigth: number;
	parceltype: number;
	floorstatus: string;
	did: number;
	handoverdate: number;
	duedate: number;
	content: string;
	acceptancedate: number;
	seqno: string;
	cusbarcode: string;
	lmsorderid: number;

	get currentBarcode() {
		return this.barcode ? this.barcode : this.lmsid.toString();
	}
}
