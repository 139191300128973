import { Component, OnInit, Input } from '@angular/core';
import { CrudService } from '../../data/crud.service';
import { Attach } from '../../model/attach.model';
import { MessageService } from '../../data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { Page } from '../../model/page.model';
import { Search, SortDirection } from '../../model/search.model';
import { forkJoin } from 'rxjs';
import { AttachService } from '../../data/attach.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentUploadComponent } from './attachment-upload/attachment-upload.component';
import { download } from '../../utils/helpers';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  @Input() entity: string;
  @Input() eid: number;

  loading: boolean = false;
  rows: Attach[];
  page: Page = new Page();
  advancedSearch: Search = new Search('attach');

  constructor(private crudService: CrudService<Attach>,
    private attachService: AttachService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private modalService: NgbModal) {
    this.advancedSearch.setSortDirection(SortDirection.DESCENDING);
    this.advancedSearch.setSortField('uploaddate');
  }

  ngOnInit() {
    this.search();
  }

  loadAttachments() {

    this.loading = true;
    this.rows = [];

    const datarequest = this.crudService.searchEntities(Attach, 'attach', this.advancedSearch, this.page);
    const countrequest = this.crudService.countEntities(Attach, 'attach', this.advancedSearch);

    forkJoin([datarequest, countrequest]).subscribe(results => {
      this.page.setTotal(results[1].ct);
      this.rows = results[0];
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.attach'));
      this.loading = false;
    });
  }

  onPageChange(pageInfo: any) {
    this.page.setPageNumber(pageInfo.offset);
    this.loadAttachments();
  }

  onSortChange(sortInfo: any) {
    const sort = sortInfo.sorts[0];
    this.advancedSearch.setSortField(sort.prop);
    this.advancedSearch.setSortDirection(sort.dir);
    this.loadAttachments();
  }

  search() {
    this.advancedSearch.reset();

    if (this.entity) this.advancedSearch.addSimpleFilter('entity', this.entity);
    if (this.eid) this.advancedSearch.addSimpleFilter('eid', this.eid);

    this.page.setPageNumber(0);
    this.loadAttachments();
  }

  upload() {
    const modal = this.modalService.open(AttachmentUploadComponent, { centered: true, size: 'lg' });
    modal.componentInstance.entity = this.entity;
    modal.componentInstance.eid = this.eid;
    modal.result.then(() => {
      this.loadAttachments();
    }).catch(() => {});
  }

  download(row: Attach) {
    this.attachService.download(row.id).subscribe(response => {
      if (response) download(response, row.filename);
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.attach'));
    });
  }

  remove(row: Attach) {
    this.crudService.deleteEntity(Attach, 'attach', row).subscribe(() => {
      this.messageService.sendSuccess(this.translateService.instant('labels.attach-delete-success'),
        this.translateService.instant('labels.attach'));
        this.loadAttachments();
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.attach'));
    });
  }

}
