export class Attach {
    id: number;
    entity: string;
    eid: number;
    extension: string;
    filename: string;
    fspath: string;
    uploaddate: number;
    size: number;
    uid: number;
}
