import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin.routing.module';
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CoreComponentsModule } from '../../@core/core-components/core-components.module';
import { CustomersComponent } from './customers/customers.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CustomerComponent } from './customers/customer/customer.component';
import { AddressMapComponent } from './customers/address-map/address-map.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user/user.component';
import { CustomerDetailComponent } from './customers/customer/customer-detail/customer-detail.component';
import { CustomerAddressesComponent } from './customers/customer/customer-addresses/customer-addresses.component';
import { UserDetailComponent } from './users/user/user-detail/user-detail.component';
import { UserRolesComponent } from './users/user/user-roles/user-roles.component';
import { RolesComponent } from './roles/roles.component';
import { RoleComponent } from './roles/role/role.component';
import { RoleFeaturesComponent } from './roles/role-features/role-features.component';
import { DispatchModule } from '../dispatch/dispatch.module';
import { CustomersListComponent } from './customers/customers-list/customers-list.component';
import { CustomersMapComponent } from './customers/customers-map/customers-map.component';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { CustomFormsModule } from 'ngx-custom-validators';
import { AddEditCustomerComponent } from './customers/add-edit-customer/add-edit-customer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AdminRoutingModule,
    AgmCoreModule,
    NgbModule,
    NgxDatatableModule,
    CoreComponentsModule,
	  TranslateModule,
    DispatchModule,
    NgxCleaveDirectiveModule,
    CustomFormsModule
  ],
  declarations: [
    CustomersComponent,
    CustomerComponent,
    AddressMapComponent,
    UsersComponent,
    UserComponent,
    CustomerDetailComponent,
    CustomerAddressesComponent,
    UserDetailComponent,
    UserRolesComponent,
    RolesComponent,
    RoleComponent,
    RoleFeaturesComponent,
    CustomersListComponent,
    CustomersMapComponent,
    AddEditUserComponent,
    AddEditCustomerComponent
  ],
  exports: [AddressMapComponent],
  entryComponents: [AddressMapComponent, RoleComponent]
})
export class AdminModule { }
