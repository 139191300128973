import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CrudService } from '../../../@core/data/crud.service';
import { MessageService } from '../../../@core/data/message.service';
import { Parcel } from '../../../@core/model/parcel.model';

@Component({
	selector: 'app-parcel',
	templateUrl: './parcel.component.html',
	styleUrls: []
})
export class ParcelComponent implements OnInit {

	parcel: Parcel;
	loading: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private crudService: CrudService<Parcel>,
		private messageService: MessageService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.route.params.subscribe(params => {
			const id = +params['id'];
			this.loadParcel(id);
		});
	}

	loadParcel(id: number) {
		this.loading = true;
		this.crudService.getEntity(Parcel, 'parcel', id).subscribe(
			parcel => {
				this.parcel = parcel;
				console.log('Parcel', this.parcel);
				this.loading = false;
			},
			error => {
				this.messageService.sendError(error.msg, this.translateService.instant('labels.parcel'));
				this.loading = false;
			}
		);
	}

}
