import { Md5 } from 'ts-md5/dist/md5';
import { Exclude } from 'class-transformer';


export class Login {
	vapp: string;
	pushid: string;
	imsi: string;
	os: string;
	vos: string;
	device: string;
	username: string;
	password: any;

	constructor() {
		this.vapp = '0.0.1';
		this.pushid = 'xxxxxxxxxxxx';
		this.imsi = '123432343234';
		this.os = 'web';
		this.vos = '5.0';
		this.device = 'web';
	}


	set md5password(pass: string) {
		this.password = Md5.hashStr(pass);
	}

}
