import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketComponent } from './tickets/ticket/ticket.component';
import { HiveTicketsRoutingModule } from './hive-tickets-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CoreComponentsModule } from '../../@core/core-components/core-components.module';
import { FormsModule } from '@angular/forms';
import { AdminModule } from '../../backoffice/admin/admin.module';


@NgModule({
  imports: [
    CommonModule, HiveTicketsRoutingModule, TranslateModule, NgbModule, NgxDatatableModule,
    CoreComponentsModule, FormsModule, AdminModule
  ],
  declarations: [TicketsComponent, TicketComponent]
})
export class HiveTicketsModule { }
