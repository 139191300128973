import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketComponent } from './tickets/ticket/ticket.component';



const routes: Routes = [
	{
		path: '',
		component: TicketsComponent,
		data: {
			title: 'labels.tickets',
			status: false
		}
	},
	{
		path: 'ticket/:id',
		component: TicketComponent,
		data: {
			title: 'labels.dispatch',
			status: false
		}
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class HiveTicketsRoutingModule { }
