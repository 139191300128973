import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { of, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GoogleService } from '../../data/google.service';
import { Geocoding } from '../../model/geocoding.model';
import { MessageService } from '../../data/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-map-address-geocoding',
	templateUrl: './map-address-geocoding.component.html',
	styleUrls: ['./map-address-geocoding.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MapAddressGeocodingComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => MapAddressGeocodingComponent),
			multi: true,
		}
	]
})
export class MapAddressGeocodingComponent implements OnInit, ControlValueAccessor, Validator {

	@Input() disabled: boolean = false;
	@Input() name: string;
	@Input() required: boolean = false;

	@Input() geocode: boolean = true;

	@Output() startGeocoding: EventEmitter<any> = new EventEmitter<any>();
	@Output() endGeocoding: EventEmitter<any> = new EventEmitter<any>();
	@Output() addressGecoded: EventEmitter<any> = new EventEmitter<any>();

	model: any;
	loading: boolean = false;
	valid: boolean = false;

	onChange = (value: any) => { };

	constructor(private googleService: GoogleService,
		private messageService: MessageService,
		private translateService: TranslateService) {
	}

	ngOnInit() {
	}

	writeValue(obj: any) {
		this.model = cloneDeep(obj);
		if (this.model && this.geocode) {
			this.geocodeAddress();
		}
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
	}

	validate(c: AbstractControl): ValidationErrors {
		return !this.required || this.valid  ? null : {
			required: {
				valid: false
			}
		};
	}

	registerOnValidatorChange?(fn: () => void): void {
	}

	onSelectItem(event: any) {
		this.model = event.item;
		this.valid = true;
		this.onChange(this.model);
		if (this.geocode) {
			this.geocodeAddress();
		}
	}

	geocodeAddress() {
		this.startGeocoding.emit();
		this.googleService.geocoding(this.model.description).subscribe((geocoding: Geocoding[]) => {
			this.addressGecoded.emit(geocoding.length ? geocoding : undefined);
			this.endGeocoding.emit();
		}, ({error}) => {
			this.messageService.sendError(error, this.translateService.instant('labels.map'));
			this.endGeocoding.emit();
		});
	}

	onInputChange(value: any) {
		if (!this.model) {
			this.valid = false;
			this.onChange(undefined);
		} else {
			const currentValue = this.formatter(value);
			const currentModel = this.formatter(this.model);
			if (currentValue !== currentModel) {
				this.valid = false;
				this.onChange(undefined);
			}
		}
	}

	formatter = (result: any) => {
		return result.description;
	}

	_search = (term: string) => {
		if (!term) {
			return of([]);
		}
		const input = term;
		return this.googleService.autocomplete(input);
	}

	search = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => this.loading = true),
			switchMap(term => this._search(term).map(response => response)),
			tap(() => this.loading = false)
		)

}
