import { Location } from '../model/location.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import 'rxjs/add/operator/map';
import { Page } from '../model/page.model';

@Injectable()
export class LocationService {

  httpOptions: any;

  constructor(protected http: HttpClient) {}

  getLocationsByDispatch(did: number, start: number, stop: number): Observable<any> {
    return this.http.get(`/ws/location/filter/did/${did}/range/${start}/${stop}` )
        .map(res => plainToClass(Location, res as Object));
  }

}
