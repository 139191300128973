import { MapMarker } from './map.model';
import { Exclude } from 'class-transformer';

export class Address implements MapMarker {

    id: number;
    cid: number;
    address: string;
    streetno: string;
    country: string;
    isocode: string;
    region: string;
    province: string;
    town: string;
    suburb: string;
    rawaddress: string;
    placeid: string;
    zipcode: string;
    insdate: number;
    moddate: number;
    lastused: number;
    method: string;
    lat: number;
    lng: number;

    @Exclude()
    clickable: boolean = false;
    @Exclude()
    draggable: boolean = false;
}

export enum AddressMethod {
    GOOGLE = 'google',
    MANUAL = 'manual',
    IMPORTED = 'lms'
}
