import { Transform, Expose } from 'class-transformer';
import { map } from 'lodash';

export class Search {

    table: string;

    @Expose({ name: 'simple' })
    @Transform(prepareSimple, { toPlainOnly: true })
    simpleTmp = {};

    @Expose({ name: 'like' })
    @Transform(prepareLike, { toPlainOnly: true })
    likeTmp = {};

    @Expose({ name: 'range' })
    @Transform(prepareRange, { toPlainOnly: true })
    rangeTmp = {};

    @Expose({ name: 'notequal' })
    @Transform(prepareNotEqual, { toPlainOnly: true })
    notEqualTmp = {};

    @Expose({ name: 'in' })
    @Transform(prepareIn, { toPlainOnly: true })
    inTmp = {};

    @Expose({ name: 'sortfield' })
    sortField;
    @Expose({ name: 'direction' })
    sortDirection;

    constructor(table: string) {
        this.table = table;
    }

    reset() {
        this.simpleTmp = {};
        this.likeTmp = {};
        this.rangeTmp = {};
        this.notEqualTmp = {};
        this.inTmp = {};
    }

    resetSort() {
        this.sortField = undefined;
        this.sortDirection = undefined;
	}

    addSimpleFilter(field: string, value: string | number) {
       this.simpleTmp[field] = value;
    }

    addLikeFilter(field: string, value: string | number) {
       this.likeTmp[field] = value;
    }

    addRangeFilter(field: string, start: string | number, stop: string | number) {
        this.rangeTmp[field] = { start, stop };
    }

    addNotEqualFilter(field: string, value: string | number) {
        this.notEqualTmp[field] = value;
    }

    addInFilter(field: string, value: string[] | number[]) {
        this.inTmp[field] = value;
    }

    setSortField(field: string) {
        this.sortField = field;
    }

    setSortDirection(direction: string) {
        this.sortDirection = direction;
    }
}

export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc'
}

export class RangeFilter {
    field: string;
    from: any;
    to: any;
}

function prepareRange(value) {

    const output = map(value, (v, key) => ({
        field: key,
        start: v.start,
        stop: v.stop,
    }));

    return output;
}


function prepareSimple(value) {

    const output = map(value, (v, key) => ({
        field: key,
        filter: v,
    }));

    return output;
}


function prepareLike(value) {

    const output = map(value, (v, key) => ({
        field: key,
        term: v,
    }));

    return output;
}

function prepareNotEqual(value) {

    const output = map(value, (v, key) => ({
        field: key,
        filter: v,
    }));

    return output;
}

function prepareIn(value) {

    const output = map(value, (v, key) => ({
        field: key,
        filter: v.join(';'),
    }));

    return output;
}
