import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: './hive-dashboard/hive-dashboard.module#HiveDashboardModule'
  },
  {
    path: 'tickets',
    loadChildren: './hive-tickets/hive-tickets.module#HiveTicketsModule'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HiveRoutingModule { }
