import { SharedDataService } from '../data/shared-data.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { CookieService } from 'ngx-cookie-service';
import { HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';
import { plainToClass, classToPlain } from 'class-transformer';
import { Staff } from '../model/staff.model';
import { Login } from '../model/login.model';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class AuthService {

  token: string;
  tokenCookie = 'ORVAdminToken';
  userCookie = 'ORVAdminUser';


  constructor(protected http: HttpClient,
    private cookieService: CookieService,
    private sharedDataService: SharedDataService) {

    sharedDataService.currentToken.subscribe(token => { this.token = token; });
  }

  login(login: Login) {
    return this.http.get('/ws/jwt/token', {}).mergeMap( (token: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer: ' + token.jwt
        }),
      };
    return this.http.post('/ws/orvuser/login', classToPlain(login), httpOptions)
      .map(res => {
        const s: Staff = plainToClass(Staff, res as Object);
        this.sharedDataService.setLoggedUser(s);
        this.cookieService.set(this.tokenCookie, s.token);
        this.cookieService.set(this.userCookie, s.id + '');
        return s;
      });
    });
    // return Observable.of(new User('test', 'test')); mock login
  }


  logout() {
    this.cookieService.delete(this.tokenCookie);
    this.cookieService.delete(this.userCookie);
    this.sharedDataService.setLoggedUser(undefined);
  }

  hasCookie() {
    return this.cookieService.get(this.tokenCookie) && this.cookieService.get(this.userCookie);
  }
  getToken() {
    return this.cookieService.get(this.tokenCookie);
  }


  getLoggedUser() {
    const token = this.cookieService.get(this.tokenCookie);
    const user = this.cookieService.get(this.userCookie);
    this.sharedDataService.setToken(token);
    if (token && user) {
      return this.getUser(user).map(data => {
        this.token = data.token;
        this.sharedDataService.setLoggedUser(data);
        return data; }
      );
    }
    return of(undefined);
  }



  getUser(user: string): Observable<Staff> {
     return this.http.get(`/ws/orvuser/${user}`)
      .map(res => plainToClass(Staff, res as Object));
   // return Observable.of(new Staff()); //mock user endpoint

  }



}

