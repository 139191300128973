import { AuthService } from './auth.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';

import { AuthenticationRoutes } from './auth.routing';
import { SharedDataService } from '../data/shared-data.service';
import { AuthenticationGuardService } from './auth-guard.service';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule } from '@angular/forms';
import { LogoutComponent } from './logout/logout.component';
import { JwtInterceptor } from './jwt.interceptor';


const SERVICES = [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  }, 
    AuthService, 
    AuthenticationGuardService,
    SharedDataService,
    CookieService];
@NgModule({
    imports: [CommonModule, FormsModule, RouterModule.forChild(AuthenticationRoutes)],
  declarations: [ LoginComponent, LogoutComponent],
  providers: [
    ...SERVICES,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: AuthModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
