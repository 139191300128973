import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../../@core/data/crud.service';
import { User } from '../../../@core/model/user.model';
import { Page } from '../../../@core/model/page.model';
import { Search, SortDirection } from '../../../@core/model/search.model';
import { MessageService } from '../../../@core/data/message.service';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  loading: boolean = false;
  rows: User[];
  page: Page = new Page();
  advancedSearch: Search = new Search('orvuser');

  keyword: string;
  fname: string;
  lname: string;
  empcode: string;
  rsaid: string;
  jobcategory: string;

  showAdvancedSearch: boolean = true;

  constructor(private crudService: CrudService<User>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router) {
    this.advancedSearch.setSortDirection(SortDirection.ASCENDING);
    this.advancedSearch.setSortField('id');
  }

  ngOnInit() {
    this.search();
  }

  loadUsers() {

    this.loading = true;
    this.rows = [];

    const datarequest = this.crudService.searchEntities(User, 'orvuser', this.advancedSearch, this.page);
    const countrequest = this.crudService.countEntities(User, 'orvuser', this.advancedSearch);

    forkJoin([datarequest, countrequest]).subscribe(results => {
      this.page.setTotal(results[1].ct);
      this.rows = results[0];
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.user'));
      this.loading = false;
    });
  }

  onPageChange(pageInfo: any) {
    this.page.setPageNumber(pageInfo.offset);
    this.loadUsers();
  }

  onSortChange(sortInfo: any) {
    const sort = sortInfo.sorts[0];
    this.advancedSearch.setSortField(sort.prop);
    this.advancedSearch.setSortDirection(sort.dir);
    this.loadUsers();
  }

  onActivate(event: any) {
    if (event.type === 'dblclick') {
      this.router.navigateByUrl(`/admin/users/${event.row.id}`);
    }
  }

  search() {

    this.advancedSearch.reset();

    if (this.keyword) {
      this.advancedSearch.addLikeFilter(`concat(fname,'',lname,'',username,'',aka,'',empcode,'',rsaid)`, this.keyword);
    }

    if (this.fname) this.advancedSearch.addLikeFilter('fname', this.fname);
    if (this.lname) this.advancedSearch.addLikeFilter('lname', this.lname);
    if (this.empcode) this.advancedSearch.addLikeFilter('empcode', this.empcode);
    if (this.rsaid) this.advancedSearch.addLikeFilter('rsaid', this.rsaid);
    if (this.jobcategory) this.advancedSearch.addSimpleFilter('jobcategory', this.jobcategory);

    this.page.setPageNumber(0);
    this.loadUsers();
  }

	rowClass = (row) => {
		return !row.lmsid && row.jobcategory === 'DRIVER' ? 'alert-row' : '';
	}

}
