import { Component, OnInit } from '@angular/core';
import { Page } from '../../../../@core/model/page.model';
import { Search, SortDirection } from '../../../../@core/model/search.model';
import { MessageService } from '../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { CrudService } from '../../../../@core/data/crud.service';
import { Customer, CustomerType } from '../../../../@core/model/customer.model';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent implements OnInit {

  loading: boolean = false;
  page: Page = new Page();
  rows: Customer[] = [];
  advancedSearch: Search = new Search('customer');

  keyword: string;
  billing: boolean = false;

  constructor(private crudService: CrudService<Customer>,
    private translateService: TranslateService,
    private messageService: MessageService) {
    this.advancedSearch.setSortDirection(SortDirection.ASCENDING);
    this.advancedSearch.setSortField('id');
  }

  ngOnInit() {
    this.search();
  }

  loadCustomers() {

    this.loading = true;
    this.rows = [];

    const datarequest = this.crudService.searchEntities(Customer, 'customer', this.advancedSearch, this.page);
    const countrequest = this.crudService.countEntities(Customer, 'customer', this.advancedSearch);

	  forkJoin([datarequest, countrequest]).subscribe(results => {
      this.page.setTotal(results[1].ct);
      this.rows = results[0];
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.customer'));
      this.loading = false;
    });
  }

  onFormChange() {
    this.search();
  }

  onPageChange(pageInfo: any) {
    this.page.setPageNumber(pageInfo.offset);
    this.loadCustomers();
  }

  onSortChange(sortInfo: any) {
    const sort = sortInfo.sorts[0];
    this.advancedSearch.setSortField(sort.prop);
    this.advancedSearch.setSortDirection(sort.dir);
    this.loadCustomers();
  }

  search() {

    this.advancedSearch.reset();

    if (this.keyword) this.advancedSearch.addLikeFilter('name', this.keyword);
    if (this.billing) this.advancedSearch.addSimpleFilter('ctype', CustomerType.billing);

    this.page.setPageNumber(0);
    this.loadCustomers();
  }

}
