import { Component, Input, OnInit } from '@angular/core';

import { CrudService } from '../../data/crud.service';
import { MessageService } from '../../data/message.service';
import { SharedDataService } from '../../data/shared-data.service';
import { Geofence } from '../../model/geofence.model';
import { Page } from '../../model/page.model';
import { Search } from '../../model/search.model';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators/map';
import { TableColumn } from '../../model/table-column.model';

@Component({
	selector: 'app-map-table',
	templateUrl: './map-table.component.html',
	styleUrls: ['./map-table.component.scss']
})
export class MapTableComponent implements OnInit {

	@Input() type: string = 'generic';
	@Input() basepath: string = 'geofences';

	loading: boolean = true;
	page = new Page();
	rows: Geofence[] = [];
	advancedSearch: Search = new Search('geofence');
	columns: TableColumn[];
	name: string;

	constructor(
		private crudService: CrudService<Geofence>,
		private sharedDataService: SharedDataService,
		private messageService: MessageService
	) {
		this.columns = [
			{ prop: 'id', name: 'labels.id', type: 'link' },
			{ prop: 'name', name: 'labels.name', type: 'string' },
			{ prop: 'radius', name: 'labels.radius', type: 'metres' },
			{ prop: 'centerlat', name: 'labels.centerlat', type: 'number' },
			{ prop: 'centerlng', name: 'labels.centerlng', type: 'number' },
			{ prop: 'insdate', name: 'labels.insdate', type: 'date' }
		];
	}

	ngOnInit() {
		console.log('Columns', this.columns);
		this.advancedSearch.addSimpleFilter('type', this.type);
		this.loading = true;
		this.sharedDataService.currentLoggedUser.subscribe(user => {
			if (user) {
				this.refreshData();
			}
		});
	}

	onFormChange() {
		this.advancedSearch.reset();
		this.advancedSearch.addSimpleFilter('type', this.type);
		if (this.name) this.advancedSearch.addLikeFilter('name', this.name);
		this.refreshData();
	}

	refreshData() {
		this.loadData(undefined).subscribe(() => {
		}, ({ error }) => this.messageService.sendError(error, error.code));
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.refreshData();
	}

	loadData(pageInfo): Observable<any> {
		if (pageInfo) {
			this.page.setPageNumber(pageInfo.offset);
		}

		const datarequest = this.crudService.searchEntities(Geofence, 'geofence', this.advancedSearch, this.page).pipe(map((res) => res));
		const countrequest = this.crudService.countEntities(Geofence, 'geofence', this.advancedSearch).pipe(map((res) => res));

		return forkJoin([datarequest, countrequest]).map(results => {
			this.page.setTotal(results[1].ct);
			this.rows = results[0];
			this.loading = false;
		});
	}

	loadPage(pageInfo) {
		this.loadData(pageInfo).subscribe(() => { },
			({ error }) => this.messageService.sendError(error, error.code));
	}

}
