import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '../../../@core/data/message.service';
import { Search } from '../../../@core/model/search.model';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  ticket: string;
  advancedSearch: Search = new Search('ticket');

  constructor(private translateService: TranslateService,
    private modalService: NgbModal,
    private messageService: MessageService) { }

  ngOnInit() {
  }

  search() { }

  onFormChange() { }

  add() {}

}
