import { Component, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppRouterService } from './@core/data/app-router.service';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private translateService: TranslateService,
      private injector: Injector) {
      this.translateService.addLangs(['en']);
      this.translateService.setDefaultLang('en');
      this.translateService.use('en');
      // register locale for pipe
      registerLocaleData(localeEn, 'en');
      // singleton instance of AppRouterService on startup
      this.injector.get(AppRouterService);
  }
}
