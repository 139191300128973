import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Dispatch } from '../model/dispatch.model';
import 'rxjs/add/operator/map';

@Injectable()
export class DispatchService {

  httpOptions: any;

  constructor(protected http: HttpClient) {}

  getCurrentDispatchByDriver(did: number): Observable<any> {
    return this.http.get(`/ws/dispatch/fetch/${did}`)
        .map(res => plainToClass(Dispatch, res as Object));
  }

}
