import { MapPosition } from './map.model';

export class Location implements MapPosition {

    id: number;
    vid: number;
    did: number;
    insdate: number;
    locdate: number;
    lat: number;
    lng: number;
    heading: number;
    accuracy: number;
    ignition: number;
    altitude: number;
    battery: number;
    enginehrs: string;
    nid: number;
    vbuno: string;
    imei: string;
    rssi: number;
    speed: number;
    geoaddress: string;
    geosuburb: string;
    geotown: string;
    geomunicipality: string;
    geoprovince: string;
    geocountry: string;
    carrier: string;
}
