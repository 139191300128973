import { SharedDataService } from '../../data/shared-data.service';
import { AnonymousSubscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs';
import { Constructor } from '../../utils/mixins';
import { OnDestroy, OnInit, Inject } from '@angular/core';
import 'rxjs/add/observable/timer';


export function DataRefresh<DataRefreshBase extends Constructor>(Base: DataRefreshBase) { 

    class Mixin extends Base {
    private timerSubscription: AnonymousSubscription;
    private dataSubscription: AnonymousSubscription;

    autorefresh: boolean = true;
    autorefreshtime: number = 5000;

    lastUpdate = new Date();
    loading: boolean;

    init(interval: number){
        this.autorefreshtime = interval;
    }

    destroy(): void {
        console.log('ngOnDestroy');
        if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
        }
        if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
        }

    }


    cancelRefresh() {
        if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
        }
    }

    refreshData() {
        delete this.lastUpdate;
        this.lastUpdate = new Date();
        if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
        }
        if (this.autorefresh) {
        this.subscribeToData();
        }
    }

    private subscribeToData(): void {
        this.timerSubscription = Observable.timer(this.autorefreshtime)
        .subscribe(() => this.refreshData());
    }
    }

    return Mixin;
}
