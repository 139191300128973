export class Adapter {

    label: string;
    machinename: string;
    status: AdapterStatus;

    get isRunning() {
        return this.status.status === AdapterStatusType.RUNNING;
    }

    get isIdle() {
        return this.status.status === AdapterStatusType.IDLE;
    }

    get isStopped() {
        return this.status.status === AdapterStatusType.STOPPED;
    }

    get isError() {
        return this.status.status === AdapterStatusType.ERROR;
    }
}

export class AdapterStatus {

    adapter: string;
    started: number;
    status: string;
    lasterrormsg: string;
    running: boolean;
}

export enum AdapterStatusType {
    RUNNING = 'running',
    IDLE = 'idle',
    STOPPED = 'stopped',
    ERROR = 'error'
}

export enum AdapterAction {
    START = 'start',
    STOP = 'stop'
}
