import { Component, OnInit, Input } from '@angular/core';
import { User, UserRole } from '../../../../../@core/model/user.model';
import { Role } from '../../../../../@core/model/role.model';
import { UserService } from '../../../../../@core/data/user.service';
import { MessageService } from '../../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { CrudService } from '../../../../../@core/data/crud.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {

  @Input()
  user: User;

  loading: boolean = false;
  rows: Role[] = [];
  selected: Role[] = [];
  userRoles: UserRole[];

  constructor(private crudService: CrudService<Role>,
    private userService: UserService,
    private messageService: MessageService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.loadRoles();
  }

  loadRoles() {

    this.loading = true;

    const userrolesrequest = this.userService.getUserRolesByUid(this.user.id);
    const rolesrequest = this.crudService.getAll(Role, 'orvrole');

    forkJoin([userrolesrequest, rolesrequest]).subscribe(results => {
      const roles = results[1];
      this.userRoles = results[0];
      this.userRoles.forEach(userRole => {
        const index = roles.map(r => r.id).indexOf(userRole.rid);
        this.selected.push(roles[index]);
      });
      this.rows = roles;
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.user'));
      this.loading = false;
    });
  }

  onSelect(event: any) {
    const selected: Role[] = event.selected;
    const rids = this.userRoles.map(ur => ur.rid);
    const srids = selected.map(s => s.id);
    // checked
    if (srids.length > rids.length) {
      const rid = this.getDiff(srids, rids);
      this.userService.assignRoleToUser(this.user.id, rid).subscribe(userRole => {
        this.userRoles.push(userRole);
        const messages = this.translateService.instant(['labels.user-role-save-success', 'labels.user']);
        this.messageService.sendSuccess(messages['labels.user-role-save-success'], messages['labels.user']);
      }, ({error}) => {
        const index = this.selected.map(s => s.id).indexOf(rid);
        this.selected.splice(index, 1);
        this.messageService.sendError(error, this.translateService.instant('labels.user'));
      });
    // unchecked
    } else {
      const rid = this.getDiff(rids, srids);
      const index = this.userRoles.map(ur => ur.rid).indexOf(rid);
      const id = this.userRoles[index].id;
      this.userService.unassignRoleToUser(id).subscribe(() => {
        this.userRoles.splice(index, 1);
        const messages = this.translateService.instant(['labels.user-role-delete-success', 'labels.user']);
        this.messageService.sendSuccess(messages['labels.user-role-delete-success'], messages['labels.user']);
      }, ({error}) => {
        const i = this.rows.map(s => s.id).indexOf(rid);
        this.selected.push(this.rows[i]);
        this.messageService.sendError(error, this.translateService.instant('labels.user'));
      });
    }
  }

  private getDiff(l1: number[], l2: number[]): number {
    for (let i = 0; i < l1.length; i++) {
      let exists = false;
      for (let j = 0; j < l2.length && !exists; j++) {
        if (l1[i] === l2[j]) {
          exists = true;
        }
      }
      if (!exists) {
        return l1[i];
      }
    }
  }

}
