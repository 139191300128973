import { Geofence } from './../../model/geofence.model';
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { GoogleMap, LatLngBounds } from '@agm/core/services/google-maps-types';
import * as screenfull from 'screenfull';
import { AgmMap, AgmMarker } from '@agm/core';
import { cloneDeep } from 'lodash';
import { MapPosition, MapMarker } from '../../model/map.model';

declare const google;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

    @Input() lat: number = -29.858783;
    @Input() lng: number = 31.023016;
    @Input() zoom: number = 13;
    @Input() fullScreenMode: boolean = true;
    @Input() type: string;
    @Input() drawing: boolean = false;

    @ContentChild(TemplateRef) parentTemplate;
    @ViewChild('agmmap') agmmap: AgmMap;
    @ViewChild('maparea') maparea: ElementRef;

    mapfull = true;
    mapHeight: string;

    nativeMap: GoogleMap;
    drawingManager: any;

    geofencePolygon: any;

    _geofence: Geofence;

    @Input()
    set geofence(value: Geofence){
      this._geofence = cloneDeep(value);
      if (value && this.nativeMap) {
        this.addPolygon();
      }
    }

    get geofence(){
      return this._geofence;
    }

    @Input()
    geofences: Geofence[];

    _markers: MapMarker[];
    _gMarkers: any[] = [];
    _infoWindows: any = {};

    @Input()
    set markers(value: MapMarker[]) {
      this._markers = cloneDeep(value);
      if (value && this.nativeMap) {
        this.addMakers();
      }
    }

    _polyline: MapPosition[];
    _gPolyline: any;

    @Input()
    set polyline(value: MapPosition[]) {
      this._polyline = cloneDeep(value);
      if (value && this.nativeMap) {
        this.clearPolyline();
        this.addPolyline();
      }
    }

    @Output() boundChanged: EventEmitter<LatLngBounds> = new EventEmitter();

    @Output() geofenceUpdated: EventEmitter<any> = new EventEmitter();
    @Output() geofenceSelected: EventEmitter<any> = new EventEmitter();
    @Output() geofenceToDelete: EventEmitter<any> = new EventEmitter();

    @Output() markerClicked: EventEmitter<any> = new EventEmitter();
    @Output() markerDragged: EventEmitter<any> = new EventEmitter();
    @Output() zoomChanged: EventEmitter<number> = new EventEmitter();

    constructor(private _el: ElementRef) {
    }

    ngOnInit() {}

    onMapReady(e: GoogleMap) {

      this.nativeMap = e;

      if (this.drawing) {
        this.drawPolygon();
      }

      if (this.geofence) {
        this.addPolygon();
      }

      if (this._markers && this._markers.length) {
        this.addMakers();
        this.zoomToMarkers(this._markers);
      }

      if (this._polyline && this._polyline.length) {
        this.addPolyline();
        this.zoomToMarkers(this._polyline);
      }

      google.maps.event.addListener(this.nativeMap, 'idle', function(ev){

        const currentBounds = this.nativeMap.getBounds();
        const currentZoom = this.nativeMap.getZoom();
        this.boundChanged.emit({
          bounds: currentBounds,
          zoom: currentZoom
        });
        this.zoomChanged.emit(currentZoom);

      }.bind(this));
    }

    drawPolygon() {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon']
        }
      });
      this.drawingManager.setMap(this.nativeMap);
      google.maps.event.addListener(this.drawingManager, 'overlaycomplete', function(event) {
        // Polygon drawn
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          if (event.overlay && event.overlay.getPath() && event.overlay.getPath().getArray()) {
            this._geofence = new Geofence(this.type, 1, 1, 1, event.overlay.getPath().getArray());
            event.overlay.setMap(null);
            this.changeDetectorRef.detectChanges();
            if (this.geofencePolygon) {
              this.geofencePolygon.setMap(null);
            }
            this.addPolygon();
          }
        }
      }.bind(this));
    }

    addPolygon() {
      if (this._geofence && this._geofence.points && this._geofence.points.length) {
        if (this.geofencePolygon) {
          this.geofencePolygon.setMap(null);
        }
        this.geofencePolygon = new google.maps.Polygon({
          paths: this._geofence.points,
          editable: this.drawing,
          draggable: this.drawing,
          polyDraggable: this.drawing,
          fillColor: this.geofence.mapColor,
          strokeColor: this.geofence.mapColor
        });
        this.geofencePolygon.setMap(this.nativeMap);
        this.drawingManager.setDrawingMode(null);
      }
    }

    addPolyline() {
      if (this._polyline && this._polyline.length) {
        const polyline = {
          path: this._polyline,
          stroke: {
            color: '#358095',
            weight: 3
          },
          editable: false,
          draggable: false,
          geodesic: true,
          visible: true,
          static: true,
          fit: true,
          icons: [{
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
            },
            offset: '25px',
            repeat: '70px'
          }]
        };
        this._gPolyline = new google.maps.Polyline(polyline);
        this._gPolyline.setMap(this.nativeMap);
      }
    }

    clearPolyline() {
      if (this._gPolyline) {
        this._gPolyline.setMap(null);
      }
    }

    addMakers() {
      // remove no available markers
      if (this._gMarkers && this._gMarkers.length) {
        const indexes = [];
        this._gMarkers.forEach((m, index) => {
          if (!this._markers) {
            m.setMap(null);
          } else {
            let j = -1;
            for (let i = 0; i < this._markers.length; i++) {
              if (this._markers[i].id === m.id) {
                j = i;
                break;
              }
            }
            if (j < 0) {
              indexes.push(index);
              m.setMap(null);
              this._infoWindows[m.id] = undefined;
            }
          }
        });
        this._gMarkers = this._gMarkers.filter((m, i) => indexes.indexOf(i) < 0);
      }
      // add or update markers
      if (this._markers && this._markers.length) {
        this._markers.forEach((m, index) => {
          const j = this._gMarkers.map(g => g.id).indexOf(m.id);
          let marker = null;
          if (j < 0) {
            marker = new google.maps.Marker({
              position: new google.maps.LatLng(m.lat, m.lng),
              icon: m.icon,
              label: m.label,
              draggable: m.draggable,
              zIndex: (index + 1),
              id: m.id
            });
            marker.setMap(this.nativeMap);
            this._gMarkers.push(marker);
          } else {
            marker = this._gMarkers[j];
            google.maps.event.clearInstanceListeners(marker);
            marker.setPosition(new google.maps.LatLng(m.lat, m.lng));
            marker.setLabel(m.label);
            marker.setDraggable(m.draggable);
            marker.setIcon(m.icon);
            marker.setZIndex(index + 1);
          }
          // register event listener
          if (m.clickable) {
            marker.addListener('click', (event) => {
              this.clickedMarker(event, m);
              if (m.infoWindow) {
                const infowindow = new google.maps.InfoWindow({
                  content: this._el.nativeElement.querySelector('#map_' + m.id).innerHTML
                });
                infowindow.open(this.nativeMap, marker);
                infowindow.addListener('closeclick', () => {
                  this._infoWindows[m.id] = undefined;
                });
                this._infoWindows[m.id] = infowindow;
              }
            });
          }
          if (m.draggable) {
            marker.addListener('dragend', (event) => {
              const coords = { lat: event.latLng.lat(), lng: event.latLng.lng() };
              this.draggedMarker({coords}, m);
            });
          }
          // update info window content if open
          if (this._infoWindows[m.id]) {
            this._infoWindows[m.id].setContent(this._el.nativeElement.querySelector('#map_' + m.id).innerHTML);
          }
        });
      }
    }

    saveGeofence() {
      if (this.geofencePolygon && this.geofencePolygon.getPath()) {
        console.log(this.geofencePolygon.getPath());
        this._geofence.update(this.geofencePolygon.getPath().getArray());
        this.geofenceUpdated.emit(this.geofence);
        this._geofence = new Geofence(this.type);
      }
    }

    editGeofence(geofence) {
      console.log(geofence);
      this.geofenceSelected.emit(geofence);
    }

    deleteGeofence(geofence) {
      console.log('deleteGeofence', geofence);
      this.geofenceToDelete.emit(geofence);
    }

    closeFullScreen() {
      if (screenfull.enabled) {
          screenfull.exit();
      }
    }

    fullScreen() {
      if (screenfull.enabled && this.maparea && this.maparea.nativeElement) {
          screenfull.toggle(this.maparea.nativeElement);
          this.mapfull = true;
      }
    }

    clickedMarker(event: AgmMarker, marker: MapMarker) {
      this.markerClicked.emit(marker);
    }

    draggedMarker(event: any, marker: MapMarker) {
      this.markerDragged.emit({coords: event.coords, marker});
    }

    moveMap(lat: number, lng: number, zoom?: number) {
      this.nativeMap.panTo(new google.maps.LatLng(lat, lng));
      if (!!zoom) {
        this.nativeMap.setZoom(zoom);
      }
    }

    zoomToMarkers(markers: MapPosition[], zoom: number = 18) {
      const bounds = new google.maps.LatLngBounds();
      markers.forEach(marker => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });
      // workaround zoom too close after fitbounds
      google.maps.event.addListenerOnce(this.nativeMap, 'bounds_changed', function() {
        if (this.nativeMap.getZoom() > zoom) {
          this.nativeMap.setZoom(zoom);
        }
      }.bind(this));
      this.nativeMap.fitBounds(bounds);
    }

    moveToMakers(markers: MapPosition[]) {
      const bounds = new google.maps.LatLngBounds();
      markers.forEach(marker => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });
      this.nativeMap.panTo(bounds.getCenter());
    }
}
