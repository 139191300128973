import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Address } from '../model/address.model';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { Geocoding, GeocodingAddressType, GeocodingAddressComponent } from '../model/geocoding.model';

@Injectable()
export class AddressService {

    httpOptions: any;

    constructor(protected http: HttpClient) {
    }

    getAddressesByCid(cid: number): Observable<any> {
        return this.http.get(`/ws/address/filter/cid/${cid}/range/0/100` )
            .map(res => plainToClass(Address, res as Object));
    }

    saveAddress(address: Address): Observable<any> {
        return this.http.post(`/ws/address`, address )
            .map(res => plainToClass(Address, res as Object));
    }

    setAddressByGeocoding(address: Address, geocoding: Geocoding) {
        address.lat = geocoding.geometry.location.lat;
        address.lng = geocoding.geometry.location.lng;
        address.rawaddress = geocoding.formattedAddress;
        address.placeid = geocoding.placeId;
        const route = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.ROUTE);
        address.address = route ? route.longName : null;
        const streetno = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.STREET_NUMBER);
        address.streetno = streetno ? streetno.longName : null;
        const zipcode = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.POSTALCODE);
        address.zipcode = zipcode ? zipcode.longName : null;
        const country = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.COUNTRY);
        address.country = country ? country.longName : null;
        address.isocode = country ? country.shortName : null;
        let sublocality = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.SUBLOCALITY_LEVEL_1);
        if (sublocality) {
            address.suburb = sublocality.longName;
        } else {
            sublocality = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.SUBLOCALITY);
            address.suburb = sublocality ? sublocality.longName : null;
        }
        let region = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.ADMINISTRATIVE_AREA_LEVEL_1);
        if (region) {
            address.region = region.longName;
        } else {
            region = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.ADMINISTRATIVE_AREA_LEVEL_2);
            address.region = region ? region.longName : null;
        }
        const town = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.LOCALITY);
        if (town) {
            address.town = town.longName;
        } else {
            const admin1 = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.ADMINISTRATIVE_AREA_LEVEL_1);
            if (admin1) {
                const admin2 = this.getGeocodingAddressByType(geocoding, GeocodingAddressType.ADMINISTRATIVE_AREA_LEVEL_2);
                address.town = admin2 ? admin2.longName : null;
            } else {
                address.town = null;
            }
        }
    }

    private getGeocodingAddressByType(geocoding: Geocoding, type: string): GeocodingAddressComponent {
        const fields = geocoding.addressComponents.filter(a => a.types.indexOf(type) >= 0);
        return fields.length ? fields[0] : undefined;
    }

}
