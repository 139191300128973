import { Component, OnInit, Input } from '@angular/core';
import { Role } from '../../../../@core/model/role.model';
import { CrudService } from '../../../../@core/data/crud.service';
import { MessageService } from '../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  @Input()
  role: Role;

  newRole: Role;

  constructor(private crudService: CrudService<Role>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.role) {
      this.newRole = cloneDeep(this.role);
    } else {
      this.newRole = new Role();
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  save() {
    this.crudService.createEntity(Role, 'orvrole', this.newRole).subscribe(item => {
      const messages = this.translateService.instant(['labels.role-save-success', 'labels.role']);
      this.messageService.sendSuccess(messages['labels.role-save-success'], messages['labels.role']);
      this.activeModal.close(item);
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.role'));
    });
  }

}
