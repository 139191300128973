import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Column } from '../../../model/colum.model';

@Component({
  selector: 'app-move-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss']
})
export class ColumnComponent implements OnInit {

  @Input() column: Column;
  @Input() choosen: boolean;
  @Input() disabled: boolean;

  @Output() onAddColumn = new EventEmitter<Column>();
  @Output() onRemoveColumn = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit() {
  }

  onDragStart(event: DragEvent) {
  }

  onDraggableCopied(event: DragEvent) {
  }

  onDraggableLinked(event: DragEvent) {
  }

  onDraggableMoved(event: DragEvent) {
  }

  onDragCanceled(event: DragEvent) {
  }

  onDragEnd(event: DragEvent) {
  }

  removeColumn() {
    this.onRemoveColumn.emit();
  }

  addColumn(item: Column) {
    this.onAddColumn.emit(item);
  }

}
