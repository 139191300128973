import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';

import { ListItem } from '../model/list.model';

@Injectable()
export class ListService {

	httpOptions: any;

	constructor(protected http: HttpClient) {

	}

	getListItemsBySlug(slug: string): Observable<any> {
		return this.http.get(`/ws/list/${slug}/items` )
			.map(res => plainToClass(ListItem, res as Object));
	}
}
