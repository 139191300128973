import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Dispatch, DispatchStart } from '../../../../@core/model/dispatch.model';
import { CrudService } from '../../../../@core/data/crud.service';
import { Search } from '../../../../@core/model/search.model';
import { Page } from '../../../../@core/model/page.model';
import { MessageService } from '../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { MapMarker } from '../../../../@core/model/map.model';
import { AttachService } from '../../../../@core/data/attach.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MapComponent } from '../../../../@core/core-components/map/map.component';
import { PrecheckAnswer } from '../../../../@core/model/precheck.model';

@Component({
  selector: 'app-dispatch-start',
  templateUrl: './dispatch-start.component.html',
  styleUrls: ['./dispatch-start.component.scss']
})
export class DispatchStartComponent implements OnInit, OnDestroy {

  @Input() dispatch: Dispatch;

  dispatchStart: DispatchStart;
  loading: boolean = false;

  @ViewChild('map') map: MapComponent;
  markers: MapMarker[];
  sealImgOrigUrl: any;
  sealImgUrl: any;

  loadingAnswers: boolean = false;
  precheckAnswers: PrecheckAnswer[];

  constructor(private crudService: CrudService<DispatchStart>,
    private precheckAnswerService: CrudService<PrecheckAnswer>,
    private attachService: AttachService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dom: DomSanitizer,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadDispatchStart(this.dispatch.id);
  }

  ngOnDestroy() {
    if (this.sealImgOrigUrl) URL.revokeObjectURL(this.sealImgOrigUrl);
  }

  loadDispatchStart(id: number) {

    const search = new Search('dispatchstart');
    search.addSimpleFilter('did', id);

    this.loading = true;

    this.crudService.searchEntities(DispatchStart, 'dispatchstart', search, new Page(1)).subscribe(results => {
      this.dispatchStart = results && results.length ? results[0] : null;
      if (this.dispatchStart) {
        if (this.dispatchStart.sverifdate) {
          const marker: MapMarker = {
            id: this.dispatchStart.id,
            lat: this.dispatchStart.sveriflat,
            lng: this.dispatchStart.sveriflng,
            clickable: true,
            infoWindow: true
          };
          this.markers = [marker];
        }
        if (this.dispatchStart.precheckstartdate) {
          this.loadPrecheckAnswers(this.dispatch.id);
        }
      }
      this.loading = false;
    }, ({ error }) => {
      this.messageService.sendError(error.msg, this.translateService.instant('labels.dispatch'));
      this.loading = false;
		});
  }

  loadSealImage() {
    if (!this.sealImgOrigUrl) {
      this.attachService.download(+this.dispatchStart.sverifvalue).subscribe(response => {
        this.sealImgOrigUrl = URL.createObjectURL(response);
        this.sealImgUrl = this.dom.bypassSecurityTrustUrl(this.sealImgOrigUrl);
        // workaround refresh marker popup
        this.cdr.detectChanges();
        this.markers = [...this.markers];
        this.cdr.detectChanges();
      }, ({ error }) => {
        this.messageService.sendError(error.msg, this.translateService.instant('labels.dispatch'));
      });
    }
  }

  loadPrecheckAnswers(id: number) {
    const search = new Search('precheckanswer');
    search.addSimpleFilter('did', id);

    this.loadingAnswers = true;
    this.precheckAnswerService.searchEntities(PrecheckAnswer, 'precheckanswer', search, new Page(100)).subscribe(results => {
      this.precheckAnswers = results;
      this.loadingAnswers = false;
    }, ({ error }) => {
      this.messageService.sendError(error.msg, this.translateService.instant('labels.dispatch'));
      this.loadingAnswers = false;
		});
  }

}
