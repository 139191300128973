import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap} from 'rxjs/operators';
import { CrudService } from '../../data/crud.service';
import { Search } from '../../model/search.model';
import { Page } from '../../model/page.model';
import { cloneDeep } from 'lodash';
import { ClassType } from 'class-transformer/ClassTransformer';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-entity-autocomplete',
  templateUrl: './entity-autocomplete.component.html',
  styleUrls: ['./entity-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityAutocompleteComponent ),
      multi: true
    },
    {
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => EntityAutocompleteComponent),
			multi: true,
		}
  ]
})
export class EntityAutocompleteComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() entityClass: ClassType<any> = Object;
  @Input() entityName: string;
  @Input() entityField: string;
  @Input() entityFieldFormatter: (value: any) => string;
  @Input() entityFilterField: string;
  @Input() entityFilterType: string = 'simple';
  @Input() entityFilterLength: number = 10;
  @Input() entitySearch: Search;

  @Input() disabled: boolean = false;
	@Input() name: string;
  @Input() required: boolean = false;
  @Input() inputClass: string;

  model: any;
  loading: boolean = false;
  advancedSearch: Search;
  page: Page;
  valid: boolean = false;

  onChange = (value: any) => {};

  constructor(private crudService: CrudService<any>) {
  }

  ngOnInit() {
    // if undefined then the default is entityField
    if (!this.entityFilterField) {
      this.entityFilterField = this.entityField;
    }
    // clone default search if exists
    if (this.entitySearch) {
      this.advancedSearch = cloneDeep(this.entitySearch);
    } else {
      this.advancedSearch = new Search(this.entityName);
    }
    this.page = new Page(this.entityFilterLength);
  }

  writeValue(obj: any) {
    this.model = cloneDeep(obj);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  validate(c: AbstractControl): ValidationErrors {
		return !this.required || this.valid  ? null : {
			required: {
				valid: false
			}
		};
	}

	registerOnValidatorChange?(fn: () => void): void {
	}

  onSelectItem(event: any) {
    this.model = event.item;
    this.valid = true;
    this.onChange(this.model);
  }

  onInputChange(value: any) {
    if (!this.model) {
      this.valid = false;
      this.onChange(undefined);
    } else {
      const currentValue = this.formatter(value);
      const currentModel = this.formatter(this.model);
      if (currentValue !== currentModel) {
        this.valid = false;
        this.onChange(undefined);
      }
    }
  }

  formatter = (result: any) => {
    if (typeof this.entityFieldFormatter !== 'undefined')
      return this.entityFieldFormatter(result);
    else
      return result[this.entityField];
  }

  _search = (term: string) => {
    if (!term) {
      return of([]);
    }
    if (this.entityFilterType === 'simple') {
      this.advancedSearch.addSimpleFilter(this.entityFilterField, term);
    } else if (this.entityFilterType === 'like') {
      this.advancedSearch.addLikeFilter(this.entityFilterField, term);
    }
    return this.crudService.searchEntities(this.entityClass, this.entityName, this.advancedSearch, this.page);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.loading = true),
      switchMap(term => this._search(term).map(response => response)),
      tap(() => this.loading = false)
    )

}
