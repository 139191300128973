import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { NgxBarcodeModule } from 'ngx-barcode';

import { CoreComponentsModule } from '../../@core/core-components/core-components.module';
import { DeliveriesComponent } from './deliveries/deliveries.component';
import { DeliveryDetailComponent } from './deliveries/delivery-detail/delivery-detail.component';
import { DeliveryComponent } from './deliveries/delivery.component';
import { DispatchRoutingModule } from './dispatch-routing.module';
import { DispatchDetailComponent } from './dispatches/dispatch-detail/dispatch-detail.component';
import { DispatchComponent } from './dispatches/dispatch.component';
import { DispatchesComponent } from './dispatches/dispatches.component';
import { ParcelDetailComponent } from './parcels/parcel-detail/parcel-detail.component';
import { ParcelComponent } from './parcels/parcel.component';
import { ParcelsComponent } from './parcels/parcels.component';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { DeliveriesListComponent } from './deliveries/deliveries-list/deliveries-list.component';
import { DeliveriesMapComponent } from './deliveries/deliveries-map/deliveries-map.component';
import { DispatchStartComponent } from './dispatches/dispatch-start/dispatch-start.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DispatchRoutingModule,
		CoreComponentsModule,
		NgxDatatableModule,
		TranslateModule,
		NgbModule,
		MomentModule,
		NgxBarcodeModule,
		NgxCleaveDirectiveModule
	],
	declarations: [
		DispatchesComponent,
		DispatchComponent,
		DispatchDetailComponent,
		DeliveriesComponent,
		DeliveryComponent,
		DeliveryDetailComponent,
		ParcelsComponent,
		ParcelComponent,
		ParcelDetailComponent,
		DeliveriesListComponent,
		DeliveriesMapComponent,
		DispatchStartComponent
	],
	exports: [DeliveriesListComponent, DispatchesComponent, DispatchComponent]
})
export class DispatchModule { }
