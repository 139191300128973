import { Component, OnInit, Input } from '@angular/core';
import { Customer } from '../../../../../@core/model/customer.model';
import { CrudService } from '../../../../../@core/data/crud.service';
import { MessageService } from '../../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {

  @Input() customer: Customer;

  loading: boolean = false;
  parent: Customer;

  constructor(private crudService: CrudService<Customer>,
    private messageService: MessageService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    if (this.customer.parent) {
      this.loadParent(this.customer.parent);
    }
  }

  loadParent(id: number) {
    this.loading = true;
    this.crudService.getEntity(Customer, 'customer', id).subscribe(customer => {
      this.parent = customer;
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.customer'));
      this.loading = false;
    });
  }

}
