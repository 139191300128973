import 'rxjs/add/operator/map';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs/Observable';

import { PrecheckOption, PrecheckQuestion } from '../model/precheck.model';

@Injectable()
export class PrecheckService {

	constructor(protected http: HttpClient,
		) {
	}

	postSortQuestions(questions: PrecheckQuestion[]): Observable<any> {
		return this.http.post(`/ws/precheckquestion/sortall`, questions)
			.map(res => plainToClass(PrecheckQuestion, res as Object));
	}

	postSortOptions(options: PrecheckOption[]): Observable<any> {
		return this.http.post(`/ws/precheckoption/sortall`, options)
			.map(res => plainToClass(PrecheckQuestion, res as Object));
	}

}
