import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DispatchComponent } from './dispatches/dispatch.component';
import { DispatchesComponent } from './dispatches/dispatches.component';
import { DeliveryComponent } from './deliveries/delivery.component';
import { ParcelComponent } from './parcels/parcel.component';
import { DeliveriesComponent } from './deliveries/deliveries.component';
import { ParcelsComponent } from './parcels/parcels.component';

const routes: Routes = [
	{
		path: 'dispatches',
		component: DispatchesComponent,
		data: {
			title: 'labels.dispatches'
		}
	},
	{
		path: 'dispatches/:id',
		component: DispatchComponent,
		data: {
			title: 'labels.dispatch',
			urls: [
				{ title: 'labels.dispatches', url: '/dispatch/dispatches' },
				{ title: 'labels.dispatch' }
			]
		}
	},
	{
		path: 'deliveries',
		component: DeliveriesComponent,
		data: {
			title: 'labels.deliveries'
		}
	},
	{
		path: 'deliveries/:id',
		component: DeliveryComponent,
		data: {
			title: 'labels.delivery',
			urls: [
				{ title: 'labels.deliveries', url: '/dispatch/deliveries' },
				{ title: 'labels.delivery' }
			]
		}
	},
	{
		path: 'parcels',
		component: ParcelsComponent,
		data: {
			title: 'labels.parcels',
		}
	},
	{
		path: 'parcels/:id',
		component: ParcelComponent,
		data: {
			title: 'labels.parcel',
			urls: [
				{ title: 'labels.parcels', url: '/dispatch/parcels' },
				{ title: 'labels.parcel' }
			]
		}
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DispatchRoutingModule { }
