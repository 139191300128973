export class Column {

    id: string;
    label: string;
    description: string;
    disabled: boolean;

    constructor(id: string, label: string, description: string) {
        this.id = id;
        this.label = label;
        this.description = description;
    }
}

