import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Geocoding, GeocodingAutocomplete } from '../../../../@core/model/geocoding.model';
import { MapComponent } from '../../../../@core/core-components/map/map.component';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../@core/data/message.service';
import { CustomerService } from '../../../../@core/data/customer.service';
import { MapMarker } from '../../../../@core/model/map.model';

@Component({
  selector: 'app-customers-map',
  templateUrl: './customers-map.component.html',
  styleUrls: ['./customers-map.component.scss']
})
export class CustomersMapComponent implements OnInit {

  @ViewChild('map') map: MapComponent;

  markers: MapMarker[];

  address: GeocodingAutocomplete;
  addressGeocoded: Geocoding;
  metres: number = 500;

  constructor(private customerService: CustomerService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  search() {
    // move map to address
    this.map.moveMap(this.addressGeocoded.geometry.location.lat, this.addressGeocoded.geometry.location.lng, 15);
    // load nearby customers
    const location = this.addressGeocoded.geometry.location;
    this.customerService.getNearbyCustomers(location.lat, location.lng, this.metres).subscribe(customers => {
      this.markers = [];
      customers.forEach(customer => {
        const address = customer.address;
        if (address) {
          customer.clickable = true;
          customer.infoWindow = true;
          this.markers.push(customer);
        }
      });
      this.cdr.detectChanges();
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.customer'));
    });
  }

  onMetresChange() {
    if (this.metres) {
      if (this.metres <= 0) this.metres = 1;
      else if (this.metres > 5000) this.metres = 5000;
    }
  }

  onAddressGeocoded(event: Geocoding[]) {
    if (event) {
      this.addressGeocoded = event[0];
    } else {
      this.messageService.sendWarn(
        this.translateService.instant('labels.no-results-founds'),
        this.translateService.instant('labels.customer'));
    }
  }

}
