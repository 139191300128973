import { Component, Input, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { RangeFilter } from '../../model/search.model';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerRangeComponent),
      multi: true
    }
  ]
})
export class DatepickerRangeComponent implements ControlValueAccessor {

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  from: Date;
  to: Date;

  range: RangeFilter;

  showdp: boolean;

  
  @Input()
  field: string;

  @ViewChild('dp')
  datepicker: NgbDatepicker;

  writeValue(value: any) {
    if (value !== undefined) {
      this.range = value;
      this.setDates();
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  constructor(calendar: NgbCalendar) {
  }

  onDateSelection(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate) {
      this.from = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    }
    if (this.toDate) {
      this.to = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
    }
    this.setRange();
  }

  onFromChange(date: string) {
    if (date && date !== '') {
      // yyyy-mm-dd
      this.from = new Date(date);
      if (this.from) {
        this.fromDate = { year: this.from.getFullYear(), month: this.from.getMonth() + 1, day: this.from.getDate() };
        this.datepicker.navigateTo({ year: this.fromDate.year, month: this.fromDate.month });
        this.setRange();
      }
    } else {
      this.from = undefined;
      this.fromDate = undefined;
      this.range = undefined;
      this.setRange();
    }
  }

  onToChange(date: string) {
    if (date && date !== '') {
      // yyyy-mm-dd
      this.to = new Date(date);
      if (this.to) {
        this.toDate = {year: this.to.getFullYear(), month: this.to.getMonth() + 1, day: this.to.getDate() };
        this.setRange();
      }
    } else {
      this.to = undefined;
      this.toDate = undefined;
      this.range = undefined;
      this.setRange();
    }
  }

  setRange() {
    if (!this.range && (this.from || this.to)) {
      this.range = new RangeFilter();
      this.range.field = this.field;
    }
    if (this.from) {
      this.range.from = this.from.getTime();
    }
    if (this.to) {
      this.range.to = this.to.getTime();
    }
    this.propagateChange(this.range);
  }

  setDates() {
    if (this.range) {
      this.from = this.isValidDateTime(this.range.from) ? new Date(this.range.from) : undefined;
      this.to = this.isValidDateTime(this.range.to) ? new Date(this.range.to) : undefined;
      if (this.from) {
        this.fromDate = {year: this.from.getFullYear(), month: this.from.getMonth() + 1, day: this.from.getDate() };
      }
      if (this.to) {
        this.toDate = {year: this.to.getFullYear(), month: this.to.getMonth() + 1, day: this.to.getDate() };
      }
    }
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
  isValidDateTime = d => typeof d !== 'undefined' && d !== null;

}
