import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Address, AddressMethod } from '../../../../@core/model/address.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleService } from '../../../../@core/data/google.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../@core/data/message.service';
import { Geocoding, GeocodingAutocomplete } from '../../../../@core/model/geocoding.model';
import { MapComponent } from '../../../../@core/core-components/map/map.component';
import { ModalService, ConfirmModalOptions } from '../../../../@core/data/modal.service';
import { AddressService } from '../../../../@core/data/address.service';
import { cloneDeep } from 'lodash';
import { MapMarker } from '../../../../@core/model/map.model';

@Component({
  selector: 'app-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss']
})
export class AddressMapComponent implements OnInit {

  @Input() address: Address;

  @ViewChild('map') map: MapComponent;

  loading: boolean = false;
  searchAddress: GeocodingAutocomplete;
  markers: MapMarker[] = [];
  coords: any;
  geocoding: Geocoding;

  constructor(private googleService: GoogleService,
    private addressService: AddressService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
    const address = cloneDeep(this.address);
    address.draggable = true;
    this.markers = [address];
  }

  onAddressGeocoded(event: Geocoding[]) {
    if (event) {
      this.geocoding = event[0];
      // save geocoded address from autocomplete
      this.address = cloneDeep(this.address);
      this.addressService.setAddressByGeocoding(this.address, this.geocoding);
      const marker = this.markers[0];
      marker.lat = this.geocoding.geometry.location.lat;
      marker.lng = this.geocoding.geometry.location.lng;
      this.markers = [marker];
      this.map.zoomToMarkers(this.markers);
    } else {
      const messages = this.translateService.instant(['labels.no-results-founds', 'labels.customer']);
      this.messageService.sendWarn(messages['labels.no-results-founds'], messages['labels.customer']);
    }
  }

  onMarkerDragged(event: any) {
    this.coords = event.coords;
    // reset geocoding -> need reverse-geo
    this.geocoding = null;
  }

  save() {
    if (!this.geocoding) {
      this.loading = true;
      this.googleService.reverseGeocoding(this.coords.lat, this.coords.lng).subscribe(event => {
        if (event.length) {
          this.geocoding = event[0];
          this.openConfirm();
        } else {
          const messages = this.translateService.instant(['labels.no-results-founds', 'labels.customer']);
          this.messageService.sendWarn(messages['labels.no-results-founds'], messages['labels.customer']);
        }
        this.loading = false;
      }, ({error}) => {
        this.messageService.sendError(error, this.translateService.instant('labels.customer'));
        this.loading = false;
      });
    } else {
      this.openConfirm();
    }
  }

  openConfirm() {
    const options = new ConfirmModalOptions();
    options.message = this.translateService.instant('labels.info-address-save', { address: this.geocoding.formattedAddress });
    options.confirmText = this.translateService.instant('labels.yes');
    options.cancelText = this.translateService.instant('labels.no');
    this.modalService.openConfirmModal(options).then(result => {
      this.saveAddress(result);
    });
  }

  saveAddress(confirm: boolean = true) {
    const newAddress = cloneDeep(this.address);
    if (confirm) {
      this.addressService.setAddressByGeocoding(newAddress, this.geocoding);
      if (this.coords) {
        newAddress.lat = this.coords.lat;
        newAddress.lng = this.coords.lng;
      }
      newAddress.method = AddressMethod.GOOGLE;
    } else {
      newAddress.lat = this.coords ? this.coords.lat : this.geocoding.geometry.location.lat;
      newAddress.lng = this.coords ? this.coords.lng : this.geocoding.geometry.location.lng;
      if (this.searchAddress) {
        newAddress.address = this.searchAddress.description;
        newAddress.rawaddress = this.searchAddress.description;
      }
      newAddress.method = AddressMethod.MANUAL;
    }
    this.addressService.saveAddress(newAddress).subscribe(() => {
      const messages = this.translateService.instant(['labels.address-save-success', 'labels.customer']);
      this.messageService.sendSuccess(messages['labels.address-save-success'], messages['labels.customer']);
      this.activeModal.close();
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.customer'));
    });
  }

  close() {
    this.activeModal.dismiss();
  }

}
