import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { RoleFeature, Role } from '../model/role.model';

@Injectable()
export class RoleService {

    httpOptions: any;

    constructor(protected http: HttpClient) {

            
    }

    getRole(id: number): Observable<any> {
        return this.http.get(`/ws/orvrole/${id}` )
            .map(res => plainToClass(Role, res as Object));
    }

    getRoleFeaturesByRid(rid: number): Observable<any> {
        return this.http.get(`/ws/roletofeature/filter/rid/${rid}/range/0/1000` )
            .map(res => plainToClass(RoleFeature, res as Object));
    }

    assignFeatureToRole(rid: number, fid: number): Observable<any> {
        return this.http.post(`/ws/roletofeature`, { rid, fid } )
            .map(res => plainToClass(RoleFeature, res as Object));
    }

    unassignFeatureToRole(id: number): Observable<any> {
        return this.http.delete(`/ws/roletofeature/${id}` );
    }
}
