import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Column } from '../../model/colum.model';
import { groupBy, cloneDeep } from 'lodash';
import { DndDropEvent } from 'ngx-drag-drop';
import { Page } from '../../model/page.model';

@Component({
  selector: 'app-move-columns',
  templateUrl: './move-columns.component.html',
  styleUrls: ['./move-columns.component.scss']
})
export class MoveColumnsComponent implements OnInit {

  // all columns map
  columnsMap: any = {};
  // selected columns map
  selectedColumnsMap: any = {};

  newColumns: Column[] = [];
  newSelectedColumns: Column[] = [];
  newAvailableColumns: Column[] = [];

  filteredAvailableColumns: Column[] = [];
  filteredSelectedColumns: Column[] = [];
  pageAvailable: Page = new Page();
  pageSelected: Page = new Page();

  availableKeyword: string;
  selectedKeyword: string;

  @Input()
  availableTitle: string = 'Available columns';
  @Input()
  selectedTitle: string = 'Selected columns';
  @Input()
  dragTitle: string = 'Drag columns here';
  @Input()
  availableFilterable: boolean = false;
  @Input()
  selectedFilterable: boolean = false;

  @Input()
  set columns(value: Column[]) {
    const copy = cloneDeep(value);
    this.newColumns = copy;
    this.columnsMap = groupBy(copy, 'id');
    this.refreshAvailableColumns();
    this.filterAvailableColumns();
  }

  @Input()
  set selectedColumns(value: Column[]) {
    const copy = cloneDeep(value);
    this.newSelectedColumns = copy;
    this.selectedColumnsMap = groupBy(copy, 'id');
    this.refreshAvailableColumns();
    this.filterAvailableColumns();
    this.filterSelectedColumns();
  }

  @Output() onAddToSelectedColumn = new EventEmitter<Column>();
  @Output() onRemoveFromSelectedColumn = new EventEmitter<Column>();

  constructor() { }

  ngOnInit() {
  }

  onDragover(event: DragEvent) {
  }

  onDrop(event: DndDropEvent) {
    if (event.data && this.columnsMap[event.data]) {
      this.addColumn(this.columnsMap[event.data][0], event.index);
    }
  }

  refreshAvailableColumns() {
    this.newAvailableColumns = this.newColumns.filter(c => {
      return this.selectedColumnsMap[c.id] === undefined;
    });
  }

  addColumn(col: Column, index: number) {
    const coltoadd = this.columnsMap[col.id][0];
    let remindex = this.newSelectedColumns.map(c => c.id).indexOf(coltoadd.id);
    if (remindex >= index) {
        remindex++;
    }
    if (typeof index === 'undefined') {
      index = this.newSelectedColumns.length;
    }
    this.newSelectedColumns.splice(index, 0, coltoadd);
    if (remindex >= 0) {
      this.newSelectedColumns.splice(remindex, 1);
    }
    this.selectedColumnsMap[col.id] = col;
    // emit add column to selected list
    this.onAddToSelectedColumn.emit(col);
    // refresh + filter
    this.refreshAvailableColumns();
    this.filterAvailableColumns();
    this.filterSelectedColumns();
  }

  removeColumn(col: Column) {
    const index = this.newSelectedColumns.map(c => c.id).indexOf(col.id);
    this.selectedColumnsMap[col.id] = undefined;
    this.newSelectedColumns.splice(index, 1);
    // emit remove column from selected list
    this.onRemoveFromSelectedColumn.emit(col);
    // refresh + filter
    this.refreshAvailableColumns();
    this.filterAvailableColumns();
    this.filterSelectedColumns();
  }

  filterAvailableColumns() {
    this.filteredAvailableColumns = this.filter(this.newAvailableColumns, this.availableKeyword);
  }

  filterSelectedColumns() {
    this.filteredSelectedColumns = this.filter(this.newSelectedColumns, this.selectedKeyword);
  }

  onAvailableKeywordChange() {
    this.filterAvailableColumns();
  }

  onSelectedKeywordChange() {
    this.filterSelectedColumns();
  }

  onChangeAvailablePage(page: number) {
    this.pageAvailable.pageNumber = page - 1;
    this.filterAvailableColumns();
  }

  onChangeSelectedPage(page: number) {
    this.pageSelected.pageNumber = page - 1;
    this.filterSelectedColumns();
  }

  filter(list: Column[], keyword: string): Column[] {
    return list.filter(col => {
      if (!keyword) return true;
      return col.label.indexOf(keyword) >= 0;
    });
  }

  getPaginatedColumns(list: Column[], page: Page) {
    const start = page.pageNumber * page.size;
    const end = start + page.size;
    return list.slice(start, end);
  }

}
