import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs/Observable';
import { SharedDataService } from '../data/shared-data.service';
import { Staff } from '../model/staff.model';
import { AclService } from '../data/acl.service';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class AuthenticationGuardService implements CanActivate, CanActivateChild {

  loggedUser: Staff;

  constructor(private sharedDataService: SharedDataService,
    private router: Router,
    private authService: AuthService,
    private aclService: AclService) {
      this.sharedDataService.currentLoggedUser.subscribe(loggedUser => { this.loggedUser = loggedUser; });
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.loggedUser) {
      if (this.checkAclFeatures(route)) {
        return of(true);
      } else {
        return of(false);
      }
    } else {
      if (this.authService.hasCookie()) {
        this.authService.getLoggedUser().subscribe(() => {}, error => { console.log(error); });
        return of(true);
      } else {
        this.router.navigate(['login'], {});
        return of(false);
      }
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    if (this.checkAclFeatures(route)) {
      return of(true);
    } else {
      return of(false);
    }
  }

  checkAclFeatures(route: ActivatedRouteSnapshot) {
    const aclFeatures = route.data.aclFeatures;
    return this.aclService.hasPermission(aclFeatures);
  }
}
