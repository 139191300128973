import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Geocoding, GeocodingAutocomplete } from '../model/geocoding.model';
import 'rxjs/add/operator/map';

@Injectable()
export class GoogleService {

  httpOptions: any;

  constructor(protected http: HttpClient) {

      
  }

	autocomplete(input: string): Observable<any> {
		return this.http.post(`/ws/google/autocomplete`, { input } )
			.map(res => plainToClass(GeocodingAutocomplete, res as Object));
	}

  geocoding(address: string): Observable<any> {
    return this.http.post(`/ws/google/geocoding`, {address} )
        .map(res => plainToClass(Geocoding, res as Object));
  }

  reverseGeocoding(lat: number, lng: number): Observable<any> {
    return this.http.post(`/ws/google/reversegeocoding`, {lat, lng} )
        .map(res => plainToClass(Geocoding, res as Object));
  }

}
