import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../../../@core/data/message.service';
import { ActivatedRoute } from '@angular/router';
import { CrudService } from '../../../../@core/data/crud.service';
import { Feature } from '../../../../@core/model/feature.model';
import { RoleService } from '../../../../@core/data/role.service';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RoleFeature, Role } from '../../../../@core/model/role.model';
import { Column } from '../../../../@core/model/colum.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-role-features',
  templateUrl: './role-features.component.html',
  styleUrls: ['./role-features.component.scss']
})
export class RoleFeaturesComponent implements OnInit {

  loading: boolean = false;

  features: Feature[];
  roleFeatures: RoleFeature[];
  role: Role;

  availableFeatures: Column[];
  currentFeatures: Column[];

  constructor(private crudService: CrudService<Feature>,
    private roleService: RoleService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params['id'];
      this.loadFeatures(id);
    });
  }

  loadFeatures(id: number) {

    this.loading = true;

    this.availableFeatures = [];
    this.currentFeatures = [];

    const featuresrequest = this.crudService.getAll(Feature, 'systemfeature');
    const rolefeaturesrequest = this.roleService.getRoleFeaturesByRid(id);
    const rolerequest = this.roleService.getRole(id);

    forkJoin([featuresrequest, rolefeaturesrequest, rolerequest]).subscribe(results => {
      this.features = results[0];
      this.roleFeatures = results[1];
      this.role = results[2];
      this.features.forEach(feature => {
        const column = new Column(feature.id.toString(), feature.feature, feature.descr);
        const index = this.roleFeatures.map(rf => rf.fid).indexOf(feature.id);
        this.availableFeatures.push(column);
        if (index >= 0) {
          this.currentFeatures.push(column);
        }
      });
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.role'));
      this.loading = false;
    });
  }

  onAddFeature(col: Column) {
    const fid = +col.id;
    this.roleService.assignFeatureToRole(this.role.id, fid).subscribe(roleFeature => {
      this.roleFeatures.push(roleFeature);
      const messages = this.translateService.instant(['labels.role-feature-save-success', 'labels.role']);
      this.messageService.sendSuccess(messages['labels.role-feature-save-success'], messages['labels.role']);
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.role'));
    });
  }

  onRemoveFeature(col: Column) {
    const fid = +col.id;
    const index = this.roleFeatures.map(rf => rf.fid).indexOf(fid);
    const id = this.roleFeatures[index].id;
    this.roleService.unassignFeatureToRole(id).subscribe(() => {
      this.roleFeatures.splice(index, 1);
      const messages = this.translateService.instant(['labels.role-feature-delete-success', 'labels.role']);
      this.messageService.sendSuccess(messages['labels.role-feature-delete-success'], messages['labels.role']);
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.role'));
    });
  }

}
