import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Delivery } from '../model/delivery.model';
import 'rxjs/add/operator/map';

@Injectable()
export class DeliveryService {

  httpOptions: any;

  constructor(protected http: HttpClient) {}

  getNearbyDeliveries(lat: number, lng: number, meters: number, start: number, stop: number): Observable<any> {
    return this.http.get(`/ws/delivery/nearby/${lat}/${lng}/${meters}/time/${start}/${stop}` )
        .map(res => plainToClass(Delivery, res as Object));
  }

}
