import { OnInit, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AclService } from '../../data/acl.service';

@Directive({ selector: '[aclFeatures]' })
export class AclDirective implements OnInit {

    @Input()
    aclFeatures: string | string[];

    constructor(private aclService: AclService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) { }

    ngOnInit(): void {
        if (this.aclService.hasPermission(this.aclFeatures)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
