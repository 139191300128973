import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '../../model/login.model';
import { Staff } from '../../model/staff.model';
import { AuthService } from '../auth.service';
import { SharedDataService } from '../../data/shared-data.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  logindata: Login = new Login();
  message: string;
  loggedUser: Staff;

  constructor(private authService: AuthService, 
    private sharedDataService: SharedDataService,
    private router: Router) { 

        this.sharedDataService.currentLoggedUser.subscribe(u => {this.loggedUser = u; if(u){ this.router.navigateByUrl('/starter');}});

    }

  ngOnInit() {

    if(this.loggedUser){
      this.router.navigateByUrl('/starter');

    }
  }


  login(){
    this.authService.login(this.logindata).subscribe(data => {
    
      if(data){
        this.loggedUser = data;
        
      } else{
        this.message = 'Login failed';
      }
    }, error => {
      console.log('error', error);
      this.message = 'Login failed';
    });
  }


  ngAfterViewInit() {
    /*$(function() {
            $(".preloader").fadeOut();
        });
        $(function() {
            (<any>$('[data-toggle="tooltip"]')).tooltip()
        });
        $('#to-recover').on("click", function() {
            $("#loginform").slideUp();
            $("#recoverform").fadeIn();
        });*/
  }


}
