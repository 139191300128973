import { VehicleMapItem, VehicleClusterMap } from './../model/vehiclemapitem';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SharedDataService } from './shared-data.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { Staff } from '../model/staff.model';
import { LatLngBounds } from '@agm/core';

@Injectable()
export class VehicleService {

  httpOptions: any;
  loggedUser: Staff;

  constructor(protected http: HttpClient,
    private sharedDataService: SharedDataService) {

      
    this.sharedDataService.currentLoggedUser.subscribe(user => this.loggedUser = user);
  }

  getMapItems(nelat: number, nelng: number, swlat: number, swlng: number, ldate?: number): Observable<any> {
    if (!ldate) {
      return this.http.get(`/ws/vehiclemapitem/square/${nelat}/${nelng}/${swlat}/${swlng}` )
        .map(res => plainToClass(VehicleMapItem, res as Object));
    } else {
      return this.http.get(`/ws/vehiclemapitem/square/${nelat}/${nelng}/${swlat}/${swlng}/${ldate}` )
        .map(res => plainToClass(VehicleMapItem, res as Object));
    }
  }

  getMapByFleetcode(fleetcode: string): Observable<any> {
    return this.http.get(`/ws/vehiclemapitem/filter/fleetcode/${fleetcode}/range/0/20` )
      .map(res => plainToClass(VehicleMapItem, res as Object));
  }

  getClusterMapItems(nelat: number, nelng: number, swlat: number, swlng: number,  ldate?: number): Observable<any> {
    if (!ldate) {
      return this.http.get(`/ws/vehiclemapitem/cluster/${nelat}/${nelng}/${swlat}/${swlng}` )
        .map(res => plainToClass(VehicleClusterMap, res as Object));
    } else {
      return this.http.get(`/ws/vehiclemapitem/cluster/${nelat}/${nelng}/${swlat}/${swlng}/${ldate}` )
        .map(res => plainToClass(VehicleClusterMap, res as Object));
    }
  }

}
