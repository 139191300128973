import { Exclude } from 'class-transformer';
import { User } from './user.model';

export class PrecheckQuestion {
	id: number;
	ptype: string;
	question: string;
	qtype: 'freetext' | 'select' | 'image';
	sort: number = 0;
	mandatory: number;

	@Exclude({ toPlainOnly: true })
	set _mandatory(value: boolean) {
		this.mandatory = value ? 1 : 0;
	}

	constructor(type: string) {
		this.ptype = type;
	}
}

export class PrecheckOption {
	id?: number;
	answer: string;
	sort: number = 0;
	pid: number;

	@Exclude({ toPlainOnly: true })
	set _pid(value: number) {
		this.pid = value;
	}
}

export class PrecheckAnswer {
	id: number;
	qid: number;
	answer: string;
	attachid: number;
	uid: number;
	did: number;
	answerdate: number;
	driver: User;
    question: PrecheckQuestion;
}
