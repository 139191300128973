import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import 'rxjs/add/operator/map';
import { UserRole } from '../model/user.model';

@Injectable()
export class UserService {

  httpOptions: any;

  constructor(protected http: HttpClient) {}

  getUserRolesByUid(uid: number): Observable<any> {
    return this.http.get(`/ws/usertorole/filter/uid/${uid}/range/0/100` )
      .map(res => plainToClass(UserRole, res as Object));
  }

  assignRoleToUser(uid: number, rid: number): Observable<any> {
    return this.http.post(`/ws/usertorole`, { uid, rid } )
      .map(res => plainToClass(UserRole, res as Object));
  }

  unassignRoleToUser(id: number): Observable<any> {
    return this.http.delete(`/ws/usertorole/${id}` );
  }

}

