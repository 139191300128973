import { NgModule } from '@angular/core';

import { RoundPipe } from './pipes/round.pipe';

@NgModule({
	imports: [
	],
	exports: [
		RoundPipe,
	],
	declarations: [RoundPipe],
})
export class PipesModule {
}
