import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { AuthenticationGuardService } from './@core/auth/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/starter', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'admin',
        loadChildren: './backoffice/admin/admin.module#AdminModule'
      },
      {
        path: 'staff',
        loadChildren: './backoffice/staff/staff.module#StaffModule'
      },
      {
        path: 'dispatch',
        loadChildren: './backoffice/dispatch/dispatch.module#DispatchModule'
	  },
	  {
        path: 'fleet',
        loadChildren: './backoffice/fleet/fleet.module#FleetModule'
      },
      {
        path: 'conf',
        loadChildren: './backoffice/configuration/configuration.module#ConfigurationModule'
      },
      {
        path: 'hive',
        loadChildren: './hive/hive.module#HiveModule'
      },
    ],
    canActivate: [AuthenticationGuardService],
    canActivateChild: [AuthenticationGuardService]
  },
  /*
  {
    path: '**',
    redirectTo: 'starter'
  }
  */
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule {}
