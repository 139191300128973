import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

@Injectable()
export class AclService {

    constructor(private authService: AuthService) {}

    hasPermission(aclFeatures: string | string[]): boolean {
        const token = this.authService.getToken();
        const dToken = jwtDecode(token);
        const userFeatures = dToken.features;
        if (!aclFeatures) return true;
        else if (!userFeatures) return false;
        const features = this.toArray(aclFeatures);
        return features.every(feature => (userFeatures.indexOf(feature) >= 0));
    }

    private toArray(value: string | string[]): string[] {
        return typeof value === 'string' ? [value] : value;
    }
}
