import { MapMarker } from './map.model';
import { Exclude } from 'class-transformer';

export class Delivery implements MapMarker {

	id: number;
	did: number;
	aid: number;
	customerid: number;
	lmsorder: number;
	waybillid: number;
	address: string;
	town?: string;
	province: string;
	country: string;
	lat: number;
	lng: number;
	placeid?: string;
	what3words?: string;
	zipcode: string;
	billingcustid: number;
	eta?: number;
	actualeta?: number;
	deliverydate?: number;
	deliverby: number;
	deliveryorder: number;
	tsgeofenceenter?: number;
	tsscanningstart?: number;
	tsscanningstop?: number;
	tspodsignature?: number;
	numprcls: number;
	geocodingstatus: string;

	@Exclude()
    clickable: boolean = false;
    @Exclude()
    infoWindow: boolean = false;

	get fullAddress() {
		return `${this.address}, ${this.town} (${this.province}), ${this.country}`;
	}

	get delta() {
		return this.actualeta - this.eta;
	}

	displayDelta(item: number) {
		return Math.round(item);
	}
}

