import { Component, OnInit } from '@angular/core';
import { User, Gender } from '../../../../@core/model/user.model';
import { CrudService } from '../../../../@core/data/crud.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../../../@core/data/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Md5 } from 'ts-md5';
import { newDate } from '../../../../@core/utils/helpers';

const RSAID_PATTERN = `^(((\\d{2}((0[13578]|1[02])(0[1-9]|[12]\\d|3[01])|(0[13456789]|1[012])
(0[1-9]|[12]\\d|30)|02(0[1-9]|1\\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\\d{4})( |-)(\\d{3})|(\\d{7}))`;

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit {

  user: User;
  loading: boolean = false;

  genders = Object.keys(Gender).map(k => Gender[k]);
  rsaidPattern = RSAID_PATTERN;
  maxDate = newDate();

  constructor(private crudService: CrudService<User>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params['id'];
      if (id) {
        this.loadUser(id);
      } else {
        this.user = new User();
      }
    });
  }

  loadUser(id: number) {
    this.loading = true;
    this.crudService.getEntity(User, 'orvuser', id).subscribe(user => {
      this.user = user;
      this.user.password = '';
      if (user.engageddate) this.user.engageddate = user.engageddate * 1000;
      if (user.joineddate) this.user.joineddate = user.joineddate * 1000;
      if (user.annualsalary) this.user.annualsalary = user.annualsalary / 100;
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.user'));
      this.loading = false;
    });
  }

  submit() {
    const newUser = cloneDeep(this.user);
    if (newUser.password) newUser.password = Md5.hashStr(newUser.password) as string;
    if (newUser.engageddate) newUser.engageddate = newUser.engageddate / 1000;
    if (newUser.joineddate) newUser.joineddate = newUser.joineddate / 1000;
    if (newUser.annualsalary) newUser.annualsalary = Math.round(newUser.annualsalary * 100);
    this.crudService.createEntity(User, 'orvuser', newUser).subscribe(user => {
      this.messageService.sendSuccess(this.translateService.instant('labels.user-save-success'),
        this.translateService.instant('labels.user'));
      this.router.navigate([`/admin/users/${user.id}`]);
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.user'));
      this.loading = false;
    });
  }

}
