import { Component, OnInit } from '@angular/core';
import { Customer, CustomerType } from '../../../../@core/model/customer.model';
import { CrudService } from '../../../../@core/data/crud.service';
import { MessageService } from '../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Search } from '../../../../@core/model/search.model';

@Component({
  selector: 'app-add-edit-customer',
  templateUrl: './add-edit-customer.component.html',
  styleUrls: ['./add-edit-customer.component.scss']
})
export class AddEditCustomerComponent implements OnInit {

  customer: Customer;
  loading: boolean = false;

  selectedParent: Customer;

  types = Object.keys(CustomerType).map(k => CustomerType[k]);
  parentSearch: Search = new Search('customer');

  constructor(private crudService: CrudService<Customer>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params['id'];
      if (id) {
        this.loadCustomer(id);
        // parent cannot be itself
        this.parentSearch.addNotEqualFilter('id', id);
      } else {
        this.customer = new Customer();
      }
    });
  }

  loadCustomer(id: number) {
    this.loading = true;
    this.crudService.getEntity(Customer, 'customer', id).subscribe(customer => {
      this.customer = customer;
      // load parent if exists
      if (customer.parent) {
        this.crudService.getEntity(Customer, 'customer', customer.parent).subscribe(parent => {
          this.selectedParent = parent;
          this.loading = false;
        }, ({error}) => {
          this.messageService.sendError(error, this.translateService.instant('labels.customer'));
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.customer'));
      this.loading = false;
    });
  }

  submit() {
    const newCustomer = cloneDeep(this.customer);
    newCustomer.parent = this.selectedParent ? this.selectedParent.id : null;

    this.crudService.createEntity(Customer, 'customer', newCustomer).subscribe(customer => {
      this.messageService.sendSuccess(this.translateService.instant('labels.customer-save-success'),
        this.translateService.instant('labels.customer'));
      this.router.navigate([`/admin/customers/${customer.id}`]);
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.customer'));
      this.loading = false;
    });
  }

}
