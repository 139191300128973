import { Component, OnInit } from '@angular/core';
import { CrudService } from '../../../@core/data/crud.service';
import { Role } from '../../../@core/model/role.model';
import { Search } from '../../../@core/model/search.model';
import { Page } from '../../../@core/model/page.model';
import { forkJoin } from 'rxjs';
import { MessageService } from '../../../@core/data/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleComponent } from './role/role.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  loading: boolean = false;
  rows: Role[];
  advancedSearch: Search = new Search('orvrole');
  page: Page = new Page();

  constructor(private crudService: CrudService<Role>,
    private messageService: MessageService,
    private translateService: TranslateService,
    private modalService: NgbModal) {

  }

  ngOnInit() {
    this.search();
  }

  loadRoles() {

    this.loading = true;
    this.rows = [];

    const datarequest = this.crudService.searchEntities(Role, 'orvrole', this.advancedSearch, this.page);
    const countrequest = this.crudService.countEntities(Role, 'orvrole', this.advancedSearch);

    forkJoin([datarequest, countrequest]).subscribe(results => {
      this.page.setTotal(results[1].ct);
      this.rows = results[0];
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.role'));
      this.loading = false;
    });
  }

  add() {
    const modal = this.modalService.open(RoleComponent, { centered: true, size: 'lg' });
    modal.result.then(() => {
      this.loadRoles();
    }).catch(() => {});
  }

  update(row: Role, event: any) {
    // workaround click event ngx-datatable
    event.target.closest('datatable-body-cell').blur();
    const modal = this.modalService.open(RoleComponent, { centered: true, size: 'lg' });
    modal.componentInstance.role = row;
    modal.result.then(() => {
      this.loadRoles();
    }).catch(() => {});
  }

  remove(row: Role) {
    this.crudService.deleteEntity(Role, 'orvrole', row).subscribe(() => {
      const messages = this.translateService.instant(['labels.role-delete-success', 'labels.role']);
      this.messageService.sendSuccess(messages['labels.role-delete-success'], messages['labels.role']);
      this.loadRoles();
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.role'));
    });
  }

  onPageChange(pageInfo: any) {
    this.page.setPageNumber(pageInfo.offset);
    this.loadRoles();
  }

  onSortChange(sortInfo: any) {
    const sort = sortInfo.sorts[0];
    this.advancedSearch.setSortField(sort.prop);
    this.advancedSearch.setSortDirection(sort.dir);
    this.loadRoles();
  }

  search() {

    this.advancedSearch.reset();

    this.page.setPageNumber(0);
    this.loadRoles();
  }

}
