import { HiveDashboardModule } from './hive-dashboard/hive-dashboard.module';
import { HiveRoutingModule } from './hive-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiveTicketsModule } from './hive-tickets/hive-tickets.module';


@NgModule({
  imports: [
    CommonModule,
    HiveRoutingModule,
    HiveDashboardModule,
    HiveTicketsModule
  ],
  declarations: []
})
export class HiveModule { }
