import { Component, OnInit, Input } from '@angular/core';
import { SharedDataService } from '../../data/shared-data.service';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: []
})
export class InfoPanelComponent implements OnInit {

  info : any;
  configOpenRightBar;

  Object = Object;

  
  constructor(private sharedDataService: SharedDataService) {

  
    
   }
  


  ngOnInit() {
    this.sharedDataService.currentAction.subscribe(value => {
      
			if (value && value.action === 'more-info') {
          this.info = value.params;
          this.configOpenRightBar = 'shw-rside';
      } else if (value && value.action === 'close-info') {
        delete this.info;
      }
    });
  }


  toggleRightbar() {
     this.configOpenRightBar = this.configOpenRightBar === 'shw-rside' ? '' : 'shw-rside';
   // document.querySelector('.right-sidebar').classList.toggle('shw-rside');

  }

}
