
export function newDate(time: boolean = false) {
    const d = new Date();
    if (!time) {
        d.setHours(0, 0, 0, 0);
    }
    return d;
}

export function download(data: any, filename: string) {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = url;
    link.target = '_blank';
    link.download = filename;
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }, 100);
}
