
export class Geocoding {

    addressComponents: GeocodingAddressComponent[];
    formattedAddress: string;
    postcodeLocalities: string[];
    geometry: GeocodingGeometry;
    types: string[];
    partialMatch: boolean;
    placeId: string;
    plusCode?: GeocodingPlusCode;
}

export interface GeocodingAddressComponent {

    longName: string;
    shortName: string;
    types: string[];
}

interface GeocodingPlusCode {

    globalCode: string;
    compoundCode: string;
}

interface GeocodingLatLng {

    lat: number;
    lng: number;
}

interface GeocodingBounds {

    northeast: GeocodingLatLng;
    southwest: GeocodingLatLng;
}

interface GeocodingGeometry {

    bounds: GeocodingBounds;
    location: GeocodingLatLng;
    locationType: string;
    viewport: GeocodingBounds;
}

/* GEOCODE AUTOCOMPLETE MODELS & INTERFACES */
export class GeocodingAutocomplete {
	description: string;
	placeId: string;
	types: string[];
	terms: GeocodingAutocompleteTerm[];
	matchedSubstrings: GeocodingAutocompleteMatchedSubstrings[];
	structuredFormatting: GeocodingAutocompleteStructuredFormatting;
}

interface GeocodingAutocompleteTerm {
	offset: number;
	value: string;
}
interface GeocodingAutocompleteMatchedSubstrings {
	length: number;
	offset: number;
}
interface GeocodingAutocompleteStructuredFormatting {
	mainText: string;
	mainTextMatchedSubstrings: GeocodingAutocompleteMatchedSubstrings[];
	secondaryText: string;
}

export enum GeocodingAddressType {
    STREET_NUMBER = 'STREET_NUMBER',
    ROUTE = 'ROUTE',
    SUBLOCALITY = 'SUBLOCALITY',
    SUBLOCALITY_LEVEL_1 = 'SUBLOCALITY_LEVEL_1',
    ADMINISTRATIVE_AREA_LEVEL_1 = 'ADMINISTRATIVE_AREA_LEVEL_1',
    ADMINISTRATIVE_AREA_LEVEL_2 = 'ADMINISTRATIVE_AREA_LEVEL_2',
    LOCALITY = 'LOCALITY',
    COUNTRY = 'COUNTRY',
    POSTALCODE = 'POSTALCODE'
}
