import { SharedDataService } from './../../data/shared-data.service';
import { OnInit, Directive, Input, TemplateRef, ViewContainerRef, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Directive({ selector: '[tablefix]' })
export class TableFixDirective implements OnInit {

    @Input()
    @Input() tablefix: DatatableComponent; 

    constructor(private sharedDataService: SharedDataService,
        private el: ElementRef,private _view: ViewContainerRef) { }

    ngOnInit(): void {
        this.sharedDataService.currentAction.subscribe(action => {
            if(action && action.action === 'toggleside' && this.tablefix){
              this.tablefix.onFooterPage({page: this.tablefix.offset+1});
            }
          });
    }
}
