import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { Parcel } from '../model/parcel.model';
import 'rxjs/add/operator/map';

@Injectable()
export class ParcelService {

  httpOptions: any;

  constructor(protected http: HttpClient) {}

  getParcelByBarcode(barcode: string): Observable<any> {
    return this.http.get(`/ws/parcel/barcode/${barcode}`)
        .map(res => plainToClass(Parcel, res as Object));
  }

}
