import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/observable/of';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class MessageService {

    conf = { onActivateTick: true };


	constructor(private notificationsService: ToastrService) {}

    private message = new BehaviorSubject<any>(undefined);
    currentMessage = this.message.asObservable();

    sendMessage(message: any) {
        this.message.next(message);
    }

    clearMessage() {
        this.message.next(undefined);
    }

	sendSuccess(message: string, t?: string) {
        const title = t ? t : 'Success';
		const toast = this.notificationsService.success(message, title, this.conf);
	}


    sendError(message: any, t?: string) {
        const title = t ? t : 'An error occurred';
        const errormessage = message && message.msg ? message.msg : message;
        const toast = this.notificationsService.error(errormessage, title, this.conf);
    }


    sendWarn(message: string, t?: string) {
        const title = t ? t : 'Warning';
        const toast = this.notificationsService.warning(message, title, this.conf);
    }



}
