import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../core-components/confirm-modal/confirm-modal.component';

@Injectable()
export class ModalService {

    constructor(private modalService: NgbModal) {}

    openConfirmModal(options: ConfirmModalOptions): Promise<any> {
        const modal = this.modalService.open(ConfirmModalComponent, { centered: options.centered, backdrop: 'static', keyboard: false });
        modal.componentInstance.message = options.message;
        modal.componentInstance.title = options.title;
        modal.componentInstance.confirmText = options.confirmText;
        modal.componentInstance.cancelText = options.cancelText;
        return modal.result;
    }
}

export class ConfirmModalOptions {

    centered?: boolean = true;
    title?: string;
    message: string;
    confirmText?: string;
    cancelText?: string;
}
