import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPanelComponent } from './info-panel/info-panel.component';
import { ToggleFullScreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { MapComponent } from './map/map.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { DndModule } from 'ngx-drag-drop';
import { ClickOutsideModule } from 'ng4-click-outside';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { ConfsComponent } from './confs/confs.component';
import { MapTableComponent } from './map-table/map-table.component';
import { MoveColumnsComponent } from './move-columns/move-columns.component';
import { ColumnComponent } from './move-columns/column/column.component';
import { NgbTooltipModule, NgbPaginationModule, NgbTypeaheadModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ListItemsSelectorComponent } from './list-items-selector/list-items-selector.component';
import { AclDirective } from './acl/acl.directive';
import { LoaderComponent } from './loader/loader.component';
import { EntityAutocompleteComponent } from './entity-autocomplete/entity-autocomplete.component';
import { TableFixDirective } from './table-fix/table-fix.directive';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatepickerRangeComponent } from './datepicker-range/datepicker-range.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes.module';
import { MapAddressGeocodingComponent } from './map-address-geocoding/map-address-geocoding.component';
import { RoundPipe } from '../pipes/round.pipe';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { HeartbitPointComponent } from './heartbit-point/heartbit-point.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { AttachmentUploadComponent } from './attachments/attachment-upload/attachment-upload.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    NgxDatatableModule,
    NgbTooltipModule,
    DndModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgxCleaveDirectiveModule,
    ClickOutsideModule,
    TranslateModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    InfoPanelComponent,
    ToggleFullScreenDirective,
    MapComponent,
	  ConfsComponent,
    MapTableComponent,
	  MoveColumnsComponent,
    ListItemsSelectorComponent,
    AclDirective,
    TableFixDirective,
    LoaderComponent,
    EntityAutocompleteComponent,
    DatepickerComponent,
    DatepickerRangeComponent,
    MapAddressGeocodingComponent,
    ConfirmModalComponent,
    HeartbitPointComponent,
    RoundPipe,
    AttachmentsComponent
  ],
  declarations: [
    InfoPanelComponent,
    ToggleFullScreenDirective,
    MapComponent,
    ConfsComponent,
    MapTableComponent,
    MoveColumnsComponent,
    ColumnComponent,
    ListItemsSelectorComponent,
    AclDirective,
    TableFixDirective,
    LoaderComponent,
    EntityAutocompleteComponent,
    DatepickerComponent,
    DatepickerRangeComponent,
    MapAddressGeocodingComponent,
    ConfirmModalComponent,
    HeartbitPointComponent,
    AttachmentsComponent,
    AttachmentUploadComponent
  ],
  providers: [],
  entryComponents: [ConfirmModalComponent, AttachmentUploadComponent]
})
export class CoreComponentsModule {
}
