import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersComponent } from './customers/customers.component';
import { CustomerComponent } from './customers/customer/customer.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user/user.component';
import { RolesComponent } from './roles/roles.component';
import { RoleFeaturesComponent } from './roles/role-features/role-features.component';
import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { AddEditCustomerComponent } from './customers/add-edit-customer/add-edit-customer.component';

const routes: Routes = [
  {
    path: 'customers',
    component: CustomersComponent,
    data: {
      title: 'labels.customers'
    }
  },
  {
    path: 'customers/new-customer',
    component: AddEditCustomerComponent,
    data: {
      title: 'labels.new-customer',
      urls: [
        { title: 'labels.customers', url: '/admin/customers' },
        { title: 'labels.new-customer' }
      ],
      aclFeatures: ['customer.insert']
    }
  },
  {
    path: 'customers/edit-customer/:id',
    component: AddEditCustomerComponent,
    data: {
      title: 'labels.edit-customer',
      urls: [
        { title: 'labels.customers', url: '/admin/customers' },
        { title: 'labels.edit-customer' }
      ],
      aclFeatures: ['customer.update']
    }
  },
  {
    path: 'customers/:id',
    component: CustomerComponent,
    data: {
      title: 'labels.customer',
      urls: [
        { title: 'labels.customers', url: '/admin/customers' },
        { title: 'labels.customer' }
      ]
    }
  },
  {
    path: 'users',
    component: UsersComponent,
    data: {
      title: 'labels.users'
    }
  },
  {
    path: 'users/new-user',
    component: AddEditUserComponent,
    data: {
      title: 'labels.new-user',
      urls: [
        { title: 'labels.users', url: '/admin/users' },
        { title: 'labels.new-user' }
      ],
      aclFeatures: ['orvuser.insert']
    }
  },
  {
    path: 'users/edit-user/:id',
    component: AddEditUserComponent,
    data: {
      title: 'labels.edit-user',
      urls: [
        { title: 'labels.users', url: '/admin/users' },
        { title: 'labels.edit-user' }
      ],
      aclFeatures: ['orvuser.update']
    }
  },
  {
    path: 'users/:id',
    component: UserComponent,
    data: {
      title: 'labels.user',
      urls: [
        { title: 'labels.users', url: '/admin/users' },
        { title: 'labels.user' }
      ]
    }
  },
  {
    path: 'roles',
    component: RolesComponent,
    data: {
      title: 'labels.roles'
    }
  },
  {
    path: 'roles/:id/features',
    component: RoleFeaturesComponent,
    data: {
      title: 'labels.features',
      urls: [
        { title: 'labels.roles', url: '/admin/roles' },
        { title: 'labels.features' }
      ]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
