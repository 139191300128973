import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor {

  @Input() name: string;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() inputClass: string;

  hoveredDate: NgbDateStruct;
  dateStruct: NgbDateStruct;
  dateValue: Date;
  model: Date;

  writeValue(value: any) {
    if (value !== undefined) {
      this.model = value;
      this.setDates();
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  constructor(calendar: NgbCalendar) {
    // this.dateStruct = calendar.getToday();
  }

  onDateSelection(date: NgbDateStruct) {
    if (this.dateStruct) {
      this.dateValue = new Date(this.dateStruct.year, this.dateStruct.month - 1, this.dateStruct.day);
    }
    this.setModel();
  }

  onChange(date: string) {
    if (date && date !== '') {
      this.dateValue = new Date(date);
      if (this.dateValue) {
        this.dateStruct = {year: this.dateValue.getFullYear(), month: this.dateValue.getMonth() + 1, day: this.dateValue.getDate() };
      }
      this.setModel();
    } else {
      this.dateValue = undefined;
      this.dateStruct = undefined;
      this.model = undefined;
      this.setModel();
    }
  }

  setModel() {
    if (this.dateValue) {
      this.model = this.dateValue;
    }
    this.propagateChange(this.model);
  }

  setDates() {
    if (this.isValidDateTime(this.model)) {
      this.dateValue = new Date(this.model);
      if (this.dateValue) {
        this.dateStruct = {year: this.dateValue.getFullYear(), month: this.dateValue.getMonth() + 1, day: this.dateValue.getDate() };
      }
    }
  }

  isValidDateTime = d => typeof d !== 'undefined' && d !== null;

}
