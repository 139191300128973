export class List {
    id: number;
    slug: string;
    descr: string;
    valuetype: string;
}

export class ListItem {
    id: number;
    lid: number;
    itemvalue: string;
    itemlabel: string;
    sort: number;
}

export enum ListValueTtype {
    STRING = 'string'
}
