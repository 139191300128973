import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { CrudService } from '../../../@core/data/crud.service';
import { MessageService } from '../../../@core/data/message.service';
import { Delivery } from '../../../@core/model/delivery.model';

@Component({
	selector: 'app-delivery',
	templateUrl: './delivery.component.html',
	styleUrls: []
})
export class DeliveryComponent implements OnInit {

	delivery: Delivery;
	loading: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private crudService: CrudService<Delivery>,
		private messageService: MessageService,
		private translateService: TranslateService
	) {}

	ngOnInit() {
		this.route.params.subscribe(params => {
			const id = +params['id'];
			this.loadDelivery(id);
		});
	}

	loadDelivery(id: number) {
		this.loading = true;
		this.crudService.getEntity(Delivery, 'delivery', id).subscribe(
			delivery => {
				this.delivery = delivery;
				console.log('Delivery', this.delivery);
				this.loading = false;
			},
			error => {
				this.messageService.sendError(error.msg, this.translateService.instant('labels.delivery'));
				this.loading = false;
			}
		);
	}

}
