import { Component, OnInit, Input } from '@angular/core';
import { Address } from '../../../../../@core/model/address.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressMapComponent } from '../../address-map/address-map.component';
import { Customer } from '../../../../../@core/model/customer.model';
import { AddressService } from '../../../../../@core/data/address.service';
import { MessageService } from '../../../../../@core/data/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-addresses',
  templateUrl: './customer-addresses.component.html',
  styleUrls: ['./customer-addresses.component.scss']
})
export class CustomerAddressesComponent implements OnInit {

  @Input()
  customer: Customer;

  loading: boolean = false;
  rows: Address[] = [];

  constructor(private addressService: AddressService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.loadAddresses();
  }

  loadAddresses() {
    this.loading = true;
    this.addressService.getAddressesByCid(this.customer.id).subscribe(addresses => {
      this.rows = addresses;
      this.loading = false;
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.customer'));
      this.loading = false;
    });
  }

  editAddress(event: any, row: Address) {
    // workaround click event ngx-datatable
    event.target.closest('datatable-body-cell').blur();
    this.openMap(row);
  }

  addAddress() {
    const address = new Address();
    address.cid = this.customer.id;
    this.openMap(address);
  }

  openMap(address: Address) {
    const modalRef = this.modalService.open(AddressMapComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.address = address;
    modalRef.result.then(() => this.loadAddresses()).catch(() => {});
  }

}
