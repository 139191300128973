import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { CrudService } from '../../../@core/data/crud.service';
import { MessageService } from '../../../@core/data/message.service';
import { Delivery } from '../../../@core/model/delivery.model';
import { Parcel } from '../../../@core/model/parcel.model';
import { Page } from '../../../@core/model/page.model';
import { Search, RangeFilter } from '../../../@core/model/search.model';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Customer } from '../../../@core/model/customer.model';
import { ParcelService } from '../../../@core/data/parcel.service';
import { newDate } from '../../../@core/utils/helpers';

@Component({
	selector: 'app-parcels',
	templateUrl: './parcels.component.html',
	styleUrls: ['./parcels.component.scss']
})
export class ParcelsComponent implements OnInit, AfterViewInit {

	@Input() delivery: Delivery;

	@ViewChild('barcodeInput') barcodeInput: ElementRef;

	loading: boolean = false;
	page: Page = new Page();
	advancedSearch: Search;
  	rows: Delivery[];

	barcode: string;
	customer: Customer;
	acceptancedate: RangeFilter;

	constructor(
		private crudService: CrudService<Parcel>,
		private parcelService: ParcelService,
		private messageService: MessageService,
		private translateService: TranslateService
	) {
	}

	ngOnInit() {
		if (this.delivery) {
			this.advancedSearch = new Search('parcel');
		} else {
			this.advancedSearch = new Search('parcel join delivery on delivery.id = parcel.did');
		}
		this.search();
	}

	ngAfterViewInit() {
		if (!this.delivery)
			this.barcodeInput.nativeElement.focus();
	}

	loadData() {
		this.loading = true;
		this.rows = [];

		if (this.barcode) {
			this.parcelService.getParcelByBarcode(this.barcode).subscribe(parcel => {
				this.rows = parcel ? [parcel] : [];
				this.page.setTotal(this.rows.length);
				this.loading = false;
			}, ({ error }) => {
				this.messageService.sendError(error.msg, this.translateService.instant('labels.parcel'));
				this.loading = false;
			});
		} else {
			const datarequest = this.crudService.searchEntities(Parcel, 'parcel', this.advancedSearch, this.page);
			const countrequest = this.crudService.countEntities(Parcel, 'parcel', this.advancedSearch);

			forkJoin([datarequest, countrequest]).subscribe(results => {
				this.page.setTotal(results[1].ct);
				this.rows = results[0];
				this.loading = false;
			}, ({ error }) => {
				this.messageService.sendError(error.msg, this.translateService.instant('labels.parcel'));
				this.loading = false;
			});
		}
	}

  	onPageChange(pageInfo: any) {
		this.page.setPageNumber(pageInfo.offset);
		this.loadData();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.loadData();
	}

	search() {

		this.advancedSearch.reset();

		if (this.delivery) {
			this.advancedSearch.addSimpleFilter('did', this.delivery.id);
		} else {
			if (this.customer) this.advancedSearch.addSimpleFilter('customerid', this.customer.id);
			if (this.acceptancedate) {
				const start = this.acceptancedate.from ? this.acceptancedate.from : 0;
				const stop = this.acceptancedate.to ? this.acceptancedate.to : newDate().getTime();
				this.advancedSearch.addRangeFilter('acceptancedate', (start / 1000), (stop / 1000));
			}
		}

		this.page.setPageNumber(0);
		this.loadData();
	}

}
