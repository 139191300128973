import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../../../@core/model/user.model';
import { MessageService } from '../../../../@core/data/message.service';
import { CrudService } from '../../../../@core/data/crud.service';
import { TranslateService } from '@ngx-translate/core';
import { DispatchStatus, Dispatch } from '../../../../@core/model/dispatch.model';
import { DispatchService } from '../../../../@core/data/dispatch.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  loading: boolean = false;
  user: User;
  currentDispatch: Dispatch;

  upcomingStatus = DispatchStatus.IDLE;
  historyStatus = DispatchStatus.COMPLETED;

  constructor(private crudService: CrudService<User>,
    private dispatchService: DispatchService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = +params['id'];
      this.loadUser(id);
    });
  }

  loadUser(id: number) {
    this.loading = true;
    this.crudService.getEntity(User, 'orvuser', id).subscribe(user => {
      this.user = user;
      if (this.user.isDriver) {
        // load current dispatch if exists
        this.dispatchService.getCurrentDispatchByDriver(this.user.id).subscribe(dispatch => {
          this.currentDispatch = dispatch;
          this.loading = false;
        }, () => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    }, ({error}) => {
      this.messageService.sendError(error, this.translateService.instant('labels.user'));
      this.loading = false;
    });
  }

}
